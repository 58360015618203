import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/common/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/common/Headings.js";
import { SectionDescription } from "components/common/Typography.js";
import { ReactComponent as QuoteIconBase } from "images/quotes-l.svg"
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg"
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg"

import "slick-carousel/slick/slick.css";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 text-gray-100 bg-primary-300 px-5 md:px-10 mx-2 md:mx-20 mb-20 rounded-2xl`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-white text-sm lg:text-base font-normal`;

const Title = tw.h4`text-3xl font-normal text-center font-serif`;
export default ({
  // subheading = "",
  heading = "Why us?",
  description = "We engage students through our enrichment, remedial and support programs. We provide instruction for elementary pupils for all strands identified by the Ontario Ministry of Education curricula guidelines. Our programs are designed to support the Canadian Curriculum subjects that include: Reading, Writing, Math, Science, Social –Studies, French, Technology, and Media Literacy.",
}) => {
  const [sliderRef, setSliderRef] = useState(null)

  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {/* {subheading && <Subheading>{subheading}</Subheading>} */}
          <Title>{heading}</Title>
        </HeadingContainer>
        <Description>{description}</Description>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
