import React, { useState, Component } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container, ContentWithPaddingXl } from "components/common/Layouts.js";
import CourseRequirement from "components/course/Requirement.js";
import CourseConsiderations from "components/course/Considerations.js";
import CoursesDetailsFaq from "components/course/Faq.js";
import Sphere from "../../images/sphere.png";
import "../../config.js";
import axios from "axios";
//const Quote = tw.h5`font-normal text-center md:text-left text-sm text-xl lg:text-base xl:text-lg p-4`;
const Quote = tw.h4`text-center font-normal md:text-left text-sm lg:text-base xl:text-lg p-4`;
const SubTitle = tw.h4`leading-relaxed text-sm lg:text-base md:text-left`;
const SubTitleCenter = tw.h4`leading-relaxed text-sm lg:text-base text-center`;
const SubTitleTime = tw.h4`leading-relaxed text-sm lg:text-base md:text-center`;
const SubTitle1 = tw.h4`leading-relaxed text-lg  pt-8`;
const Description = tw.p`mt-4 text-center md:text-left text-sm lg:text-base leading-relaxed text-secondary-100`;
const ExpecDescription = tw.p`text-center md:text-left text-sm lg:text-base leading-relaxed text-secondary-100`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto sm:py-2 md:py-4 lg:py-12 px-5 lg:px-12`;

const Row = tw.div`flex flex-wrap overflow-hidden pt-4`;
const Column = tw.div``;
const TextColumn = tw(Column)`w-full overflow-hidden`;

const Title = tw.h4`sm:text-xl md:text-2xl text-3xl font-bold text-gray-700 text-left`;
const PageTitle = tw.h4`text-3xl font-bold text-gray-700 text-center mt-4`;

const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base text-gray-600 `;
const Paragraph1 = tw.p`my-2 lg:my-2 text-sm lg:text-base text-gray-600 mx-4 leading-6`;
const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row mt-8`;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 mb-8 xl:mt-0 justify-center`;
const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;

const tabs = {
  Description: [],
  //   Requirement: [],
  //   Expectation: [],
};

//const Title = tw.h4`text-3xl font-bold text-gray-900`;
class CourseDetails extends Component {
  constructor() {
    super();
    document.title = "Course - Learning Beam";
    this.state = {
      course_code: "",
      data: [],
      description: "",
      unitCourseDetails: [],
      finalCourseDetail: [],
      resourcesRequired: [],
      expectation: [],
      strategies: [],
      strategiesPoint: [],
      dummyValue: "",
      MyComponent: "",
      total_hours: "",
      course_description: "",
      strategyData: [],
      courseData: "",
      getAssessmentDesc: "",
      getAssessmentPoints: [],
      getFinalGradeDesc: [],
      getFinalGradePoints: [],
      getReportData: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getCourseData();
  }

  getCourseData = () => {
    const body = new FormData();
    body.append("courseCode", this.props.courseCode);

    axios
      .post(global.config.main.base_url + "/getChapters", body)
      .then((response) => {
        // console.log(response.data);

        let totalHours = 0;
        const res = response?.data?.map(
          (data) => (totalHours += Number(data.duration))
        );

        this.setState({
          data: response.data,
          total_hours: totalHours,
        });
      });
  };

  scrollToBottom(arg) {
    document.getElementById(arg.tabName).scrollIntoView();
  }
  render() {
    const tabsKeys = Object.keys(tabs);
    const [activeTab, setActiveTab] = tabsKeys[0];
    return (
      <Container>
        <SingleColumn>
          {/* <div id="Description" /> Rohit*/}
          {/* <center>
            <HeaderRow style={{ display: "inline-block" }}>
              <TabsControl>
                {Object.keys(tabs).map((tabName, index) => (
                  <TabControl key={index} name={tabName}>
                    <a href={"#" + tabName}>{tabName}</a>
                  </TabControl>
                ))}
              </TabsControl>
            </HeaderRow>
          </center> */}

          <Row>
            <TextColumn>
              <Title>Course Description</Title>
            </TextColumn>
          </Row>
          <Paragraph>{this.props.description}</Paragraph>
          <Paragraph>
            To know more about course. Click on{" "}
            <a href={global.config.main.course_pdf_url + this.props.courseCode + ".pdf"} target="_blank" rel="noopener noreferrer" style={{ color: "blue", textDecoration: "underline" }}>
              Course Outline
            </a>
          </Paragraph>
          <table style={{ tableLayout: "fixed", width: "100%" }}>
            <thead style={{ backgroundColor: "#F9F9F9" }}>
              <tr className="trBorder">
                {/*<th className="courseDetailTableTdFirstBorder trHeading" style={{"width":"80%"}}>
									Unit Titles and Descriptions</th>*/}
                <th
                  className="courseDetailTableTdFirstBorder"
                  style={{ width: "75%" }}
                >
                  <Quote>
                    <center>Unit Titles</center>
                  </Quote>
                </th>
                <th className="w-1/2">
                  <Quote>
                    <center>Time</center>
                  </Quote>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.data && (
                <>
                  {this.state.data.map((data, index) => (
                    <tr className="trBorder" key={data.id}>
                      <td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
                        <SubTitle>{data.title}</SubTitle>
                      </td>
                      <td className="courseDetailTableTdPadding">
                        <SubTitleTime>{data.duration} hours</SubTitleTime>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          <table style={{ tableLayout: "fixed", width: "100%" }}>
            {/* <thead style={{ backgroundColor: "#F9F9F9" }}>
              <tr className="trBorder">
                <th style={{ width: "75%" }}>
                  <Quote>
                    <center>Final Assesments</center>
                  </Quote>
                </th>
                <th
                  style={{
                    color: "#34465B",
                    fontFamily: "LibreBaskerville-Regular",
                    fontSize: "18px",
                    fontWeight: "500",
                    letterSpacing: "-0.2px",
                    lineHeight: "27px",
                    padding: "1.5rem",
                    width: "25%",
                  }}
                ></th>
              </tr>
            </thead> */}
            <tbody>
              {/* {this.state.finalCourseDetail && (
                <>
                  {this.state.finalCourseDetail.map((data, index) => (
                    <tr className="trBorder">
                      <td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
                        <SubTitle>{data.course_heading}</SubTitle>
                        <Description>{data.course_subheading}</Description>
                      </td>
                      <td className="courseDetailTableTdPadding">
                        <SubTitleTime>{data.course_time}</SubTitleTime>
                      </td>
                    </tr>
                  ))}
                </>
			  )} */}
              <tr className="trBorder" style={{ backgroundColor: "#E4F0FE" }}>
                <td
                  className="courseDetailTableTdFirstBorder"
                  style={{ width: "75%" }}
                >
                  <SubTitleCenter>Total</SubTitleCenter>
                </td>
                <td className="courseDetailTableTdPadding">
                  <SubTitleTime>{this.state.total_hours} Hours</SubTitleTime>
                </td>
              </tr>
            </tbody>
          </table>
          {/* <div id="Requirement" />
          {this.state.courseData != "" ? (
            <>
              <CourseRequirement
                courseData={this.state.courseData}
                data={this.props.data}
              />
            </>
          ) : (
            ""
          )}
          <div id="Expectation" />
          <Row>
            <TextColumn>
              <Title>Course Expectations</Title>
            </TextColumn>
          </Row>
          <table
            style={{ tableLayout: "fixed", width: "100%", marginTop: "2em" }}
          >
            {this.state.expectation != false ? (
              <>
                {this.state.expectation.map((data, index) => (
                  <>
                    <thead style={{ backgroundColor: "#F9F9F9" }}>
                      <tr className="trBorder">
                        <th
                          style={{
                            color: "#34465B",
                            fontFamily: "LibreBaskerville-Regular",
                            fontSize: "14px",
                            fontWeight: "400",
                            letterSpacing: "-0.2px",
                            lineHeight: "27px",
                            padding: "1.5rem",
                            width: "10%",
                          }}
                        ></th>
                        <th className="w-1/2">
                          <Quote>
                            <center>{data.heading}</center>
                          </Quote>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.headingData.map((data, index) => (
                        <tr className="trBorder">
                          <td className="courseDetailTableTdFirstBorder">
                            <SubTitleCenter>
                              {data.expectations_order}
                            </SubTitleCenter>
                          </td>
                          <td className="courseDetailTableTdPadding">
                            <ExpecDescription>
                              {data.expectations_desc}
                            </ExpecDescription>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                ))}
              </>
            ) : (
              ""
            )}
          </table> */}

          {/*<p className="margin tlStrategyMargin">Teaching and Learning Strategies:</p>*/}
          {/* <SubTitle1>Teaching and Learning Strategies:</SubTitle1>
          {this.state.strategyData != false ? (
            <>
              {this.state.strategyData.map((data, index) => (
                <>
                  <Paragraph>{data.heading}</Paragraph>
                  <FeatureList style={{ marginTop: "20px" }}>
                    {data.points != false ? (
                      <>
                        {data.points.map((data, index) => (
                          <Feature key={index}>
                            <img
                              src={Sphere}
                              alt="logo"
                              style={{ width: "9px" }}
                            />
                            <Paragraph1>{data.strategies}</Paragraph1>
                          </Feature>
                        ))}
                      </>
                    ) : (
                      ""
                    )}
                  </FeatureList>
                </>
              ))}
            </>
          ) : (
            ""
          )}

          <SubTitle1>
            Assessment, Evaluation and Reporting Strategies of Student
            Performance:
          </SubTitle1>
          {this.state.getAssessmentDesc != "" ? (
            <>
              <Paragraph>
                {this.state.getAssessmentDesc["assessment_desc"]}
              </Paragraph>
            </>
          ) : (
            ""
          )}
          <FeatureList style={{ marginTop: "20px" }}>
            {this.state.getAssessmentPoints != false ? (
              <>
                {this.state.getAssessmentPoints.map((data, index) => (
                  <Feature key={index}>
                    <img src={Sphere} alt="logo" style={{ width: "9px" }} />
                    <Paragraph1>{data.assessment_desc}</Paragraph1>
                  </Feature>
                ))}
              </>
            ) : (
              ""
            )}
          </FeatureList>

          <SubTitle1>The Final Grade</SubTitle1>
          {this.state.getFinalGradeDesc != "" ? (
            <>
              <Paragraph>
                {this.state.getFinalGradeDesc["final_grade_desc"]}
              </Paragraph>
            </>
          ) : (
            ""
          )}
          <FeatureList style={{ marginTop: "20px" }}>
            {this.state.getFinalGradePoints != false ? (
              <>
                {this.state.getFinalGradePoints.map((data, index) => (
                  <Feature key={index}>
                    <img src={Sphere} alt="logo" style={{ width: "9px" }} />
                    <Paragraph1>{data.final_points}</Paragraph1>
                  </Feature>
                ))}
              </>
            ) : (
              ""
            )}
          </FeatureList>

          {this.state.getReportData != "" ? (
            <>
              <SubTitle1>The Report Card:</SubTitle1>
              <Paragraph>{this.state.getReportData["report_desc"]}</Paragraph>
            </>
          ) : (
            ""
          )} */}

          {/* {this.state.courseData != '' ?
						<>
							<CourseConsiderations />
						</>
						:
						""
					} */}

          {/* <hr style={{ marginTop: '2em' }} /> */}

          {/* {this.state.courseData != "" ? (
            <>
              <CoursesDetailsFaq
                courseData={this.state.courseData}
                data={this.props.data}
              />
            </>
          ) : (
            ""
          )} */}
        </SingleColumn>
      </Container>
    );
  }
}
export default CourseDetails;
