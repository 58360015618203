import React, { Component } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";

import Desc from "components/students/InternationalDesc.js";
import NeedHelp from "../common/NeedHelp.js";
import MetaTags from "react-meta-tags";
import HowDoesItWork from "components/common/HowDoesItWork.js";
import Top from "components/students/Top.js";
//import mainImage from "../../images/lang_course_main.png";
import aboutUsImage from "../../images/about.png";
import mainImage from "../../images/home.png";
import CoursesOffered from "components/course/Offer.js";
import PeopleTestimonial from "components/common/Testimonials.js";
import Calendly from "components/common/Calendly.js";

const imageCss = tw`rounded-4xl`;

class International extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <AnimationRevealPage>
        <MetaTags>
          <title>Credit Courses in Canada | OSSD Diploma in Canada </title>
          <meta
            name="description"
            content="LBA offers online credit courses in Canada for International Students and Ontario Secondary School Diploma in Canada."
          />
          <meta
            name="keywords"
            content="International Students, International Students Credit Courses Canada, Credit Courses in Canada, OSSD Diploma in Canada, Ontario Secondary School Diploma"
          />
          <meta
            property="og:title"
            content="Credit Courses in Canada | OSSD Diploma in Canada"
          />
          <meta
            property="og:image"
            content="/static/media/logo.358fb4cd.svg"
            alt="International students Canada"
          />
        </MetaTags>
        <Top
          heading={<>Credit Courses & OSSD for International Students</>}
          description="Learning Beam Academy issued individual credit scores help you enroll in top rated colleges and opt for their favorite programs."
          imageSrc={mainImage}
          imageCss={imageCss}
          altContent={"Online Courses Ontario"}
          imageDecoratorBlob={true}
        />
        <hr style={{ width: "84%", marginLeft: "2.5em" }} />

        <Desc heading={<>Get Credit Courses and OSSD Diploma in Ontario</>} />
        {/* <NeedHelp /> */}
        <CoursesOffered heading={<>Ontario High School Courses</>} />
        <HowDoesItWork heading={<>How Does It Work?</>} />
        <PeopleTestimonial />
        <Calendly />
        <Footer />
      </AnimationRevealPage>
    );
  }
}
export default International;
