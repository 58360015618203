import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
//import { Container, ContentWithPaddingXl } from "components/common/Layouts.js";
//import { ContentWithPaddingXl } from "components/common/Layouts.js";
import { SectionHeading } from "components/common/Headings.js";
//import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as svg220 } from "images/220.svg";
//import svg220 from "../../images/220.svg";
import { ReactComponent as CheckboxIcon } from "../../images/checkbox-circle.svg";
export const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto pt-8 lg:pt-20`;

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-4 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

const PlanFeatures = styled.ul`
  ${tw`flex-1 lg:-mx-6 -mx-6 sm:-mx-10 pb-10 pt-5 px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10 xl:pt-5`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0 `}
    .icon {
      ${tw`w-6 h-6 text-primary-500 flex-shrink-0`}
    }
    .text {
      ${tw`font-semibold  text-secondary-250 tracking-wide ml-3`}
    }
  }
  .desc-text {
    ${tw`font-normal text-sm text-secondary-200 tracking-wide ml-8 pl-1`}
  }
`;

const Container = tw.div`relative md:px-10`;
//const Container = tw.div`relative`

const DecoratorBlob2 = styled(svg220)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-80 w-80 transform translate-x-2/3 text-primary-500`}
`;
const Title = tw.h2`text-3xl font-bold font-black text-center`;

const HeadingContainer = tw.div`mb-12`;

const SubParagraph = tw.p`mt-12 mb-8 text-base text-secondary-200 mx-auto lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-2`;

const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;

const FeatureText = tw.div`md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.h5`mt-4 text-sm lg:text-base font-bold text-secondary-200 text-left`;

const FeatureDescription = tw.p`mt-4 mb-8 text-sm lg:text-base text-gray-600 mx-auto lg:mx-0`;
const FeatureSubDescription = tw.div`mt-1 text-sm text-secondary-200 text-left`;

export default ({ heading = "" }) => {
  return (
    <Container>
      <DecoratorBlob2 />
      <ContentWithPaddingXl>
        <HeadingContainer>
          {/* {subheading && <Subheading>{subheading}</Subheading>} */}
          <Title>{heading}</Title>
        </HeadingContainer>
        {/* <FeatureHeading>1. Why Learning Beam Academy?</FeatureHeading> */}
        <FeatureDescription>
          We are authorized to grant credits to international students that
          enable them to get enrolled into globally recognized colleges/
          universities. Our Ontario Secondary School Diploma (OSSD) and Credit
          Courses from Grade 9-12 is accepted by all top colleges/ universities
          globally.
        </FeatureDescription>

        <FeatureHeading>
          What Our International Student will Gain?
        </FeatureHeading>

        <PlanFeatures>
          <div>
            <li className="feature">
              <CheckboxIcon className="icon" />
              <span className="text">
                Innovative Online Learning Experience
              </span>
            </li>
            <p className="desc-text">
              Learning Beam Academy provides a digital online learning platform
              that nurtures a new learning experience for our students. All our
              curriculum structure is designed by a highly innovative and
              experienced team of teachers. It complies 100% with the Ontario
              Ministry of Education.
            </p>
          </div>

          <div style={{ marginTop: "16px" }}>
            <li className="feature">
              <CheckboxIcon className="icon" />
              <span className="text">International Learning Experience</span>
            </li>
            <p className="desc-text">
              The Ontario education system emphasizes practical knowledge over
              theoretical knowledge. Our international students would experience
              global learning, where students are required to complete their
              assignments, quizzes, presentations and projects before being
              assigned a midterm and final Exam.
            </p>
          </div>

          <div style={{ marginTop: "16px" }}>
            <li className="feature">
              <CheckboxIcon className="icon" />
              <span className="text">Boost to Your Academic Profile</span>
            </li>
            <p className="desc-text">
              Our programs add up to your study profile. Upon completion, your
              profile holds similar credentials as a Canadian citizen.
            </p>
          </div>

          <div style={{ marginTop: "16px" }}>
            <li className="feature">
              <CheckboxIcon className="icon" />
              <span className="text">
                Gateway to Globally Recognized Colleges/ Universities
              </span>
            </li>
            <p className="desc-text">
              Our programs open up doors of all new opportunities, where
              students' profiles would be honored globally. It increases the
              chances of profile selection by globally recognized Colleges/
              Universities.
            </p>
          </div>
        </PlanFeatures>

        {/* <FeatureDescription>  - Students looking to improve a course grade or to satisfy a prerequisite for university and college applications.</FeatureDescription>
        <FeatureDescription>  - Students looking to get ahead of a semester by taking additional courses.</FeatureDescription>
        <FeatureDescription>  - Students who are looking for flexibility in their learning schedule.</FeatureDescription>
        <FeatureDescription>  - Students also wanted to complete their full Ontario Secondary School Diploma with us.</FeatureDescription>
        <FeatureDescription>  - Students looking to Upgrade the Marks For University or College.</FeatureDescription>
        <FeatureDescription>  - Students want to complete diploma anytime.</FeatureDescription> */}

        {/* <FeatureHeading>3. Fast-Track — Conveniently</FeatureHeading>
        <FeatureDescription>Students also take courses with LBA to free up their timetable for the upcoming school year, to avoid timetable conflicts, or to earn a credit not available anywhere else. Learn more here. If you’re ready to enroll into our Ontario accredited high school courses please begin by visiting our How Does it Work? page. Included in the fee are all materials, instruction by an Ontario Certified Teacher, tests, modules, simulations examinations, and access to an online tutor which is a 24/7 on-demand and completely online service.</FeatureDescription> */}
      </ContentWithPaddingXl>
    </Container>
  );
};
