import React, { Component } from "react";
import { Link } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/common/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg"
import CoursePlan from "components/tuition_details/CoursePlan.js";

import axios from "axios";
import "../../config.js";
const qs = require('querystring');

const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-72 bg-center bg-cover relative rounded-t`}
`;

const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;

const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;
const Container = tw.div`relative sm:mb-2 md:mb-4 sm:mt-16 md:mt-20 lg:mx-10 border`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
	tw`md:w-6/12 mt-4 md:mt-0 text-center`,
	props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
	props.imageRounded && tw`rounded`,
	props.imageBorder && tw`border`,
	props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)(props => [
	tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
])

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
	SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
	tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
	props.buttonRounded && tw`rounded-full`
]);

const heading = (
	<>
		Designed & Developed by <span tw="text-primary-500">Professionals.</span>
	</>
);
const description = "Course Duration - 3 months";
const subdescription = "Chapters Completed - 11";
const primaryButtonText = "Join Us";
const primaryButtonUrl = "http://3.138.202.75:3001/course-payment";
const imageSrc = TeamIllustrationSrc;
const buttonRounded = true;
const imageRounded = true;
const imageBorder = false;
const imageShadow = false;
const imageCss = null;
const imageDecoratorBlob = false;
const imageDecoratorBlobCss = null;
const textOnLeft = true;

const Title = tw.h4`sm:text-2xl md:text-4xl font-bold text-white`;
const SubTitle = tw.h4`leading-relaxed text-lg text-white`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const Paragraph = tw.p`my-4 text-sm lg:text-base text-white`;

class Top extends Component {
	constructor() {
		super();
		this.state = {
			image: '',
			heading: '',
			textOnLeft: '',
			buttonRounded: '',
			data: '',
			chapters: '',
			course_code: '',
			course_name: '',
			course_title: '',
			credit_value: '',
			department: '',
			department_head: '',
			duration: '',
			fee: '',
			id: '',
			prerequisite: '',
			published_date: '',
			updated: '',

		}
	}
	componentDidMount() {
		console.log("TOP PART PROPS");
		console.log(this.props);
		this.setState({
			image: this.props.imageSrc,
			textOnLeft: this.props.textOnLeft,
			buttonRounded: this.props.buttonRounded,
			data: this.props.data,

		});
		//this.getCourseData();
	}
	getCourseData = () => {
		axios.get(global.config.main.tuition_api_url + '?' + this.props.data)
			.then(response => {
				this.setState({
					data: response.data,
				});
				this.state.data = response.data;
				this.setState({
					dummyEntry: "DUMMY VALUE",
				});


				this.setState({
					heading: response.data.getCourseBasicDetail.fee,
					duration: response.data.getCourseBasicDetail.duration,
					chapters: response.data.getCourseBasicDetail.chapters,
					course_code: response.data.getCourseBasicDetail.course_code,
					course_name: response.data.getCourseBasicDetail.course_name,
					course_title: response.data.getCourseBasicDetail.course_title,
					credit_value: response.data.getCourseBasicDetail.credit_value,
					department: response.data.getCourseBasicDetail.department,
					department_head: response.data.getCourseBasicDetail.department_head,
					duration: response.data.getCourseBasicDetail.duration,
					fee: response.data.getCourseBasicDetail.fee,
					id: response.data.getCourseBasicDetail.id,
					prerequisite: response.data.getCourseBasicDetail.prerequisite,
					published_date: response.data.getCourseBasicDetail.published_date,
					updated: response.data.getCourseBasicDetail.updated,
				});
			});
	}
	update() {
		this.props.onUpdate(this.state.heading)
	}
	render() {
		return (
			<Container>
				<TwoColumn style={{ padding: '0', marginLeft: '0', width: '100%' }}>
					<ImageColumn>
						<CardImageContainer imageSrc={this.state.image} style={{ height: 'auto', paddingLeft: '2rem', paddingTop: '6rem', paddingBottom: '8rem' }}>
							{/* <Title style={{ textAlign: 'left' }}>{this.state.course_title}</Title>
							<SubTitle style={{ textAlign: 'left' }}>({this.state.course_code})</SubTitle> */}

							{/* <Paragraph style={{ textAlign: 'left' }}>Published Date - {this.state.published_date}</Paragraph>
							<Paragraph style={{ textAlign: 'left' }}>Updated - {this.state.updated}</Paragraph>
							<Paragraph style={{ textAlign: 'left' }}>Credit Value - {this.state.credit_value}</Paragraph>
							<Paragraph style={{ textAlign: 'left' }}>Prerequisite - {this.state.prerequisite}</Paragraph> */}
						</CardImageContainer>
					</ImageColumn>
					<TextColumn style={{ padding: '1rem', paddingBottom: '2em' }} textOnLeft={this.state.textOnLeft}>
						<CoursePlan data={this.props.data} heading={<>Courses we offer</>} />
						<TextContent>

						</TextContent>
					</TextColumn>
				</TwoColumn>
			</Container>
		)
	}
};
export default (Top);
