import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/common/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/common/Headings.js";
import { SectionDescription } from "components/common/Typography.js";
import { ReactComponent as QuoteIconBase } from "images/quotes-l.svg"
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg"
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg"
import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/shield-icon.svg";
import ReliableIconImage from "../../images/shield-icon.svg";
import SimpleIconImage from "../../images/shield-icon.svg";

import "slick-carousel/slick/slick.css";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 text-gray-100 bg-primary-300 px-5 mb-20 mt-16 pb-10`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-0 px-12`}
`;

const HeadingContainer = tw.div`pt-12`;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-secondary-250 text-sm lg:text-base font-normal`;

const Title = tw.h4`text-3xl font-normal text-gray-700 text-center font-serif`;


const Column = styled.div`
  ${tw`w-full md:w-1/2 lg:w-1/3 md:px-8 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col min-w-full mx-auto max-w-xs items-start px-6 py-8 rounded-lg mt-12 bg-white`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-left rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`md:mt-2 text-left`}
  }

  .title {
    ${tw`mt-2 font-normal text-sm leading-none text-secondary-450`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-450 text-base leading-normal`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;


export default ({
  // subheading = "",
  heading = "How does it work?",
  description = "We engage students through our enrichment, remedial and support programs. We provide instruction for elementary pupils for all strands identified by the Ontario Ministry of Education curricula guidelines. Our programs are designed to support the Canadian Curriculum subjects that include: Reading, Writing, Math, Science, Social –Studies, French, Technology, and Media Literacy.",
}) => {

  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Step 1",
      description: "Profile Evalutaion"
    },
    {
      imageSrc: SupportIconImage, title: "Step 2",
      description: "Guide Relevant Colleges, Universities"
    },
    {
      imageSrc: CustomizeIconImage, title: "Step 3",
      description: "Apply to Selected College, University",
    },
    {
      imageSrc: ReliableIconImage, title: "Step 4",
      description: "Receive Approval"
    },
    {
      imageSrc: FastIconImage, title: "Step 5",
      description: "Apply for Visa."
    },
    {
      imageSrc: SimpleIconImage, title: "Step 6",
      description: "Fly to Destination"
    }
  ];

  return (
    <PrimaryBackgroundContainer>
      <HeadingContainer>
        {/* {subheading && <Subheading>{subheading}</Subheading>} */}
        <Title>{heading}</Title>
      </HeadingContainer>
      <ThreeColumnContainer>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </PrimaryBackgroundContainer>
  );
};
