import React, { useState, useEffect } from "react";
import { Subheading as SubheadingBase } from "components/common/Headings.js";
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/common/Layouts.js";
import { SectionHeading } from "components/common/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";





import { SectionDescription } from "components/common/Typography.js";
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 sm:px-4 lg:px-0 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;
const PlanDurationSwitcher = tw.div`block w-full max-w-xs sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1`;
const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full sm:text-2xl md:text-4xl`;

const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-center md:items-start relative`;
const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed -mx-8 bg-white rounded-t-lg`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;

const Plan = styled.div`
  ${tw`w-full max-w-72 mt-8 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  ${props =>
    props.featured &&
    css`
      ${tw`border-0 border-gray-200 shadow-none`}
    `}
`;

const plans = [
  {
    name: "Pro Plan",
    no: ["1", "2"],
    durationPrices: ["1", "2"],
    mainFeature: "Suited for Production Websites",
    features: ["60 Templates", "8 Landing Pages", "22 Internal Pages", "Priority Assistance", "Lifetime Updates"],
    featured: true
  }
];

const PrimaryButton = tw.button`font-bold sm:text-sm md:text-base sm:px-6 md:px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;

const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;
const Title = tw.h4`text-3xl font-bold text-white`;
const SubTitle = tw.h4`leading-relaxed text-lg text-black`;
//const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const Heading1 = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-medium text-gray-700 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;
const Paragraph = tw.p`my-4 text-sm lg:text-base text-white`;
const SubTitle1 = tw.div`font-bold tracking-wide text-secondary-100`;

const TextContent = tw.div`text-center md:text-left`;
//const Subheading = tw(SubheadingBase)`text-center md:text-left`;
//const Heading = tw(
//  SectionHeading
//)`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;
//const Description = tw.p`mt-4 text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const Description1 = tw.h4`sm:text-2xl md:text-3xl font-bold mb-8 mt-2`;
const Description = tw(SectionDescription)`w-full text-center text-sm md:text-base text-secondary-250`;

function CoursePlan(props) {
  const heading = "Checkout the Menu";
  const tabs = {
    Citizen: getBasic(),
    International: getPremium(),
  }
  const [activeDurationIndex, setActiveDurationIndex] = useState(0);
  const [firstName, setFirstName] = React.useState(null);
  let abcd = [];
  const [state, setState] = React.useState([]);

  React.useEffect(() => {
    setState(state.concat(props.data.getCourseBasicDetail));
    // fetch(global.config.main.tuition_api_url + '?' + props.data)
    //   .then(results => results.json())
    //   .then(data => {
    //     setFirstName(data.getCourseBasicDetail);
    //     abcd = data.getCourseBasicDetail;
    //     //setState([...state, data.getFaqPoints]);
    //     setState(state.concat(data.getCourseBasicDetail));
    //     console.log("FUNCTIONAL");
    //     console.log(abcd);
    //   });
  }, []);
  //const courseTime = abcd.duration;
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container>

      {state.map((data, index) => (
        <>
          <Description1>Tuition, Grade {data.grade}</Description1>
        </>
      ))}




      < PrimaryButton as="a" href="/joinus" > Join Us</PrimaryButton >


    </Container >
  );
};

/* This function is only there for demo purposes. It populates placeholder cards */
const getBasic = () => {
  const cards = [
    {
      no: '1',
      fee: "$200",
      duration: "5 months",
      chapter: "8",
      url: "course/course_code=ENG4C"
    },
  ];
  //return cards.sort(() => Math.random() - 0.5);
  return cards;
};

const getPremium = () => {
  const cards = [
    {
      no: '2',
      fee: "$579",
      duration: "10 months",
      chapter: "15",
      url: "course/course_code=ENG4C"
    },
  ];
  //return cards.sort(() => Math.random() - 0.5);
  return cards;
};

const planDurations = [
  {
    switcherText: "Citizen",
  },
  {
    switcherText: "International",
  }
]

export default CoursePlan
