import React, { Component } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";
import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg";
import serverSecureIllustrationImageSrc from "images/building.jpg";
import Header from "components/common/HeaderNew.js";
import CourseTop from "components/course/Top.js";
import CoursesDetails from "components/course/Details.js";
import MetaTags from "react-meta-tags";
import Calendly from "components/common/Calendly.js";
import "../../config.js";
import axios from "axios";
const qs = require("querystring");

class Courses extends Component {
  constructor() {
    super();
    document.title = "Course - Learning Beam";
    this.state = {
      showPayment: false,
      fee: "",
      courseData: "",
      dummyValue: "",
    };
  }
  componentDidMount = () => {
    this.getCourseData();
  };
  getCourseData = async () => {
    const body = new FormData();
    body.append("courseCode", this.props.match.params.course_code);
    // axios.post(global.config.main.base_url + '?' + this.props.data)
    axios
      .post(global.config.main.base_url + "/getCourseDetails", body)
      .then((response) => {
        this.setState({
          courseData: response.data[0],
        });
      });
  };

  onUpdate(data) {
    this.setState({ showPayment: true, fee: data });
  }

  render() {
    return (
      <AnimationRevealPage>
        <MetaTags>
          <title>
            Learning Beam Academy – Online Courses |Grade 9|Grade
            10|Grade11|Grade12{" "}
          </title>
          <meta
            name="description"
            content="Online Grade 9 Math course in Ontario. Grade 10 Math Ontario- Principles of Mathematics., Grade 11 functions Ontario, Grade 11 English- Course emphasizes the development of literacy, Grade 12 data management- Data Management, Grade 12 English Online course- Consolidates the literacy, Grade 12 Advanced Functions, Grade 12 Physics, Grade 12 Chemistry, Grade 12 Calculus and Vectors."
          />
          <meta
            name="keywords"
            content="Grade 9 Ontario, MPM1D, Grade 10 Ontario,  MPM2D, Grade 11 functions Ontario, MCR3U, ENG3U, Grade 12 Ontario, Data Management, MDM4U, Physics, SPH4U, Chemistry, SCH4U, Advanced Functions, MHF4U, Calculus and Vectors, MCV4U, Grade 12 English online, ENG4U, Biology Ontario, SBI4U"
          />
          <meta
            property="og:title"
            content="Learning Beam Academy – Online Courses |Grade 9|Grade 10|Grade11|Grade12"
          />
          <meta
            property="og:image"
            content="/static/media/logo.358fb4cd.svg"
            alt="Grade 9 to 12 Ontario Credit Courses"
          />
        </MetaTags>
        <Header />
        <CourseTop
          courseData={this.state.courseData}
          imageSrc={serverSecureIllustrationImageSrc}
        />
        <CoursesDetails
          courseCode={this.props.match.params.course_code}
          description={this.state.courseData.description}
        />

        <Calendly />
        <Footer />
      </AnimationRevealPage>
    );
  }
}
export default Courses;
