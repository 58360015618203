import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/common/Layouts.js";
import { SectionHeading } from "components/common/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import axios from "axios";
//const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const HighlightedText = tw.span`  px-4 transform -skew-x-12 inline-block`;
const Heading = tw(SectionHeading)`pt-16 pb-16`;
const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex  flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div` mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Wrapper = tw.div`mb-10`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;
const Title = tw.h4`text-3xl font-bold text-gray-700 text-center`;

const Links = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;
class Offer extends React.Component {
	constructor() {
		super();
		document.title = "Course - Learning Beam";
		this.state = {
			data: [],
			courseGrade: ','
		}
	}
	componentDidMount() {
		console.log("GRADE");
		console.log(this.props.grade);
		this.getCourseData();
		if ('grade=9' == this.props.grade) {
			this.setState({
				courseGrade: 'Grade 9'
			});
		}
		else if ('grade=10' == this.props.grade) {
			this.setState({
				courseGrade: 'Grade 10'
			});
		}
		else if ('grade=11' == this.props.grade) {
			this.setState({
				courseGrade: 'Grade 11'
			});
		}
		else if ('grade=12' == this.props.grade) {
			this.setState({
				courseGrade: 'Grade 12'
			});
		}
	}
	getCourseData = () => {
		axios.get(global.config.main.api_url + '?' + this.props.grade)
			.then(response => {
				console.log(response);
				this.setState({
					data: response.data,
				});
			})
	}
	render() {
		return (
			<Container>
				<Wrapper>
					<div className="homeCoursesTabContent">
						<Heading>Course <HighlightedText>{this.state.courseGrade}</HighlightedText></Heading>
						<TabContent className="homeCoursesTabContent">
							{this.state.data.map((data, index) => (
								<CardContainer key={index} style={{ display: 'inline-block' }}>
									<Links href={`/course/course_code=${data.course_code}`}>
										<Card className="group courseCard d-flex" initial="rest" whileHover="hover" animate="rest">
											<CardText className="courseCardText courseCardPaddingTop" style={{ wordBreak: 'break-all' }}>
												<CardTitle>{data.course_name}</CardTitle>
												<CardContent>{data.course_title}</CardContent>
											</CardText>
										</Card>
									</Links>
								</CardContainer>
							))}
						</TabContent>
					</div>
				</Wrapper>
			</Container>
		)
	}
};

export default Offer
