import React from "react";
import { Link } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as InstagramIcon } from "../../images/instagram.svg";
import { ReactComponent as QuoraIcon } from "../../images/quora-icon.svg";

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-4 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const Content = tw.div`max-w-screen-xl mx-auto pt-2 pb-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-8 lg:pt-16 lg:pb-8 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(
  Column
)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
//const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 sm:text-base text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const DeveloperLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`;
const CopyrightNotice = tw.div``;
const CompanyInfo = tw.div``;

const Divider = tw.div`my-8 border-b border-gray-800`;

export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <WideColumn>
            <LogoContainer>
              <LogoImg src={LogoImage} style={{ width: "200px" }} />
              {/* <LogoText>Learning Beam Academy</LogoText> */}
            </LogoContainer>
            <CompanyDescription>
              Ontario's best Online Private School
            </CompanyDescription>
            <SocialLinksContainer>
              <SocialLink
                href="https://www.facebook.com/Learning-Beam-Academy-HIGH-School-Credits-101612661497471"
                target="_blank" rel="noopener noreferrer"
              >
                <FacebookIcon />
              </SocialLink>
              <SocialLink
                href="https://twitter.com/Learning_beam"
                target="_blank" rel="noopener noreferrer"
              >
                <TwitterIcon />
              </SocialLink>
              {/* <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink> */}
              <SocialLink
                href="https://www.instagram.com/learningbeamacademy/"
                target="_blank" rel="noopener noreferrer"
              >
                <InstagramIcon />
              </SocialLink>
              <SocialLink
                href="https://www.quora.com/profile/Learning-Beam-Academy/"
                target="_blank" rel="noopener noreferrer"
              >
                <QuoraIcon />
              </SocialLink>
            </SocialLinksContainer>
          </WideColumn>
          <Column>
            <ColumnHeading>Our Offers</ColumnHeading>
            <LinkList>
              <LinkListItem>
                {/*<Link to="/courses/0">Grade 9</Link>*/}
                <Link to="/high-school-credit-courses-online">
                  <>Credit Courses</>
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link to="/students/ontario-secondary-school-diploma">
                  <>OSSD Diploma</>
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link to={{ pathname: "https://www.learningbeam-ielts.com/" }} target="_blank" rel="noopener noreferrer">
                  <>IELTS</>
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link to="/tuitions">
                  <>Tuitions</>
                </Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>About</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link to="/best-high-school-ontario">Contact Us</Link>
              </LinkListItem>
              <LinkListItem>
                <Link to="/">
                  <>Privacy Policy</>
                </Link>
              </LinkListItem>
              <LinkListItem>
                <a
                  href={global.config.main.ministry_letter_pdf}
                  target="_blank" rel="noopener noreferrer"
                >
                  <>Ministry Approved Letter</>
                </a>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              {/* <LinkListItem>
                <Link href="tel:416-887-0769">416-887-0769</Link>
              </LinkListItem> */}
              <LinkListItem>
                <Link href="mailto:info@learningbeamacademy.ca">
                  info@learningbeamacademy.ca
                </Link>
              </LinkListItem>
              <LinkListItem>
                <a href="tel:+1-416-887-0769">+1-416-887-0769</a>
              </LinkListItem>
              <LinkListItem>
                <Link href="#"></Link>
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        {/* <Divider />
        <CopyrightAndCompanyInfoRow>
          <CopyrightNotice>&copy; Copyright 2021, Learning Beam Academy</CopyrightNotice>
          <CompanyInfo>Developed By - <DeveloperLink href="https://www.think-straight.com" target="_blank">ThinkStraight</DeveloperLink></CompanyInfo>
        </CopyrightAndCompanyInfoRow> */}
      </Content>
    </Container>
  );
};
