import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { useQuery } from "react-query";
import axios from "axios";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/common/Layouts.js";
import { SectionHeading } from "components/common/Headings.js";
import { Loading } from "components/common/Loading.js";

import "../../config.js";
// import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";
// import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

const HeaderRow = tw.div`flex justify-center items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12`;
const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const Heading = tw.h2`text-3xl font-bold text-gray-700 text-center`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(
  motion.div
)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-20`;

const Card = tw(
  motion.a
)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
// const CardImageContainer = styled.div`
//   ${props => css`background-image: url("${props.imageSrc}");`}
//   ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
// `;
// const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
// const CardRating = styled.div`
//   ${tw`mr-1 text-sm font-bold flex items-end`}
//   svg {
//     ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
//   }
// `;

// const CardHoverOverlay = styled(motion.div)`
//   background-color: rgba(255, 255, 255, 0.5);
//   ${tw`absolute inset-0 flex justify-center items-center`}
// `;
// const CardButton = tw(PrimaryButtonBase)`text-sm`;

// const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-sm md:text-lg font-semibold items-center break-words`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600 break-words`;
const CardCode = tw.p`mt-1 text-sm font-medium group-hover:text-primary-500 text-gray-800`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-5xl`;

// const CardPrice = tw.p`mt-4 text-xl font-bold`;

// const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
//   ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
// `;
// const Title = tw.h4`text-3xl font-bold text-gray-700 text-center`;
export default ({ heading = "" }) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const [tabs, setTabs] = useState({});
  const [tabsKeys, setTabsKeys] = useState([]);
  const [activeTab, setActiveTab] = useState(null);

  const fetchFunction = async () =>
    await axios.get(`${global.config.main.base_url}/course?offers=list`);

  const { isLoading, error, data } = useQuery(`offers-api`, fetchFunction);

  const createTabs = () => {
    const offerData = data?.data;

    const formatOfferData = offerData?.map((offer) => ({
      title: offer.courseName,
      content: offer.courseDesc,
      code: offer.courseCode,
      grade: offer.grade,
      url: `/course/${offer.courseCode}`,
    }));

    let gradeList = offerData?.map((offer) => offer.grade);
    gradeList = Array.from(new Set(gradeList));
    gradeList = gradeList.sort((a, b) => a - b);
    console.log(gradeList);

    setTabsKeys(gradeList);
    setActiveTab(gradeList[3]);

    const newTabs = {};

    const getTabs = gradeList?.map((grade) => {
      const SameGradesOffer = formatOfferData.filter(
        (offer) => offer.grade === grade
      );

      newTabs[grade] = SameGradesOffer;
    });

    // console.log("newTabs", newTabs);
    setTabs(newTabs);
  };

  useEffect(() => {
    if (data) {
      createTabs();
    }
  }, [data]);

  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <Heading>{heading}</Heading>
          <HeadingDescription>
            Learning Beam Academy offers individual credit courses to upgrade
            your existing high school marks. It helps you in improving your
            study profile and fulfills post secondary requirements when you
            apply for colleges and universities globally. We offer a range of
            courses. Learning Beam Academy evaluates your existing academic
            credentials under Prior Learning Assessment Requirement (PLAR) and
            based on evaluation suggests you relevant credit courses.
          </HeadingDescription>
        </HeadingInfoContainer>
        {/* <center><Title>{heading}</Title></center> */}
        {/* <HeaderRow style={{ display: 'inline-block', marginTop: '1em' }}> */}
        <HeaderRow>
          <TabsControl style={{ marginLeft: "0" }}>
            {tabsKeys?.map((tabName, index) => (
              <TabControl
                key={index}
                active={activeTab === tabName}
                onClick={() => setActiveTab(tabName)}
              >
                Grade {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys?.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: { opacity: 1, scale: 1, display: "flex" },
              hidden: { opacity: 0, scale: 0.8, display: "none" },
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
            className="homeCoursesTabContent"
          >
            {tabs[tabKey]?.map((card, index) => (
              <CardContainer key={index}>
                <Link to={card.url}>
                  <Card
                    className="group courseCard d-flex"
                    initial="rest"
                    whileHover="hover"
                    animate="rest"
                  >
                    <CardText className="courseCardText courseCardPaddingTop">
                      <CardTitle>{card.title}</CardTitle>
                      <CardCode>[{card.code}]</CardCode>
                      <CardContent>{card.content}</CardContent>
                    </CardText>
                  </Card>
                </Link>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </Content>
    </Container>
  );
};

/* This function is only there for demo purposes. It populates placeholder cards */
const getRandomCards9 = () => {
  const cards = [
    {
      title: "Principles of Mathematics",
      content: "Principles of Mathematics",
      code: "MM123",
      url: "course/course_code=MPM1D",
    },
  ];
  return cards;
};

const getRandomCards10 = () => {
  const cards = [
    {
      title: "Principles of Mathematics",
      content: "Principles of Mathematics",
      code: "MM123",
      url: "course/course_code=MPM2D",
    },
  ];
  return cards;
};

const getRandomCards11 = () => {
  const cards = [
    {
      title: "Functions",
      content: "Functions",
      code: "MM123",
      url: "course/course_code=MCR3U",
    },
    {
      title: "English",
      content: "English",
      code: "MM123",
      url: "course/course_code=ENG3U",
    },
  ];
  return cards;
};

const getRandomCards12 = () => {
  const cards = [
    {
      title: "English",
      content: "English of University Preparation",
      code: "MM123",
      url: "course/course_code=ENG4U",
    },

    {
      title: "Mathematics",
      content: "Mathematics of Data Management",
      code: "MM123",
      url: "course/course_code=MDM4U",
    },
    {
      title: "Chemistry",
      content: "Chemistry University Preparation",
      code: "MM123",
      url: "course/course_code=SCH4U",
    },
    {
      title: "Physics",
      content: "Physics University Preparation",
      code: "MM123",
      url: "course/course_code=SPH4U",
    },
    {
      title: " Advanced Functions",
      content: " Advanced Functions",
      code: "MM123",
      url: "course/course_code=MHF4U",
    },
    {
      title: "Calculus & Vectors",
      content: "Calculus & Vectors",
      code: "MM123",
      url: "course/course_code=MCV4U",
    },
    {
      title: "Biology",
      content: "Biology",
      code: "MM123",
      url: "course/course_code=SBI4U",
    },
  ];
  return cards;
};
