import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/common/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/common/Layouts";
import { SectionDescription } from "components/common/Typography";
import styled from "styled-components";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/group1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/group2_new.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "../../images/group3_new.svg";




const Container = tw(ContainerBase)`my-8 lg:my-10 bg-primary-900 text-gray-100 -mx-8 px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(SectionDescription)`text-gray-400 text-center mx-auto max-w-screen-md`;

const StatsContainer = tw.div`mt-8 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-md justify-between mx-auto`
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`
const StatKey = tw.div`text-xl font-medium`
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black` 
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none  absolute left-0 top-0 transform -translate-x-2/3  -translate-y-2/3 z-10 `}
`;

const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none  absolute left-0 bottom-0 w-64 transform z-10 `}
`;

const DecoratorBlob3 = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none  absolute right-0 bottom-0 w-64 transform z-10 `}
`;




export default ({
  subheading = "",
  stats = [
    {
      key: "Courses",
      value: "9",
    },
    {
      key: "Happy Students",
      value: "500",
    },
    {
      key: "Years of Experience",
      value: "10",
    },
  ]
}) => {
  return (
    <Container style={{background:'linear-gradient(270deg, #0093EE 0%, #005AD7 100%)',marginTop:'0',marginBottom:'0', position:'relative'}}>
      <ContentWithPaddingXl>
	  <StatsContainer style={{marginTop:'0'}}>
		<DecoratorBlob1 style={{top:'4.16em',left:'3em'}}/>
          {stats.map((stat, index) => (
            <Stat key={index}>
				<StatValue>{stat.value}</StatValue>
              <StatKey>{stat.key}</StatKey>
            </Stat>
          ))}
		<DecoratorBlob2 style={{bottom:"-10px"}}/>
		<DecoratorBlob3 style={{bottom:"-10px",right:"-72px"}}/>
        </StatsContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
