import React, { Component } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";
import HowDoesItWork from "components/common/HowDoesItWork.js";
import Desc from "components/students/CanadaDesc.js";
import NeedHelp from "../common/NeedHelp.js";
import MetaTags from "react-meta-tags";
import PeopleTestimonial from "components/common/Testimonials.js";
import Top from "components/students/Top.js";
import WhyUs from "components/students/WhyUs.js";

import aboutUsImage from "../../images/about.png";
import CoursesOffered from "components/course/Offer.js";
import Calendly from "components/common/Calendly.js";
import courseMainImage from "../../images/courseMain.png";

const imageCss = tw`rounded-4xl`;

class Canada extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <AnimationRevealPage>
        <MetaTags>
          <title>Ontario Credit Courses Online - Ontario Students </title>
          <meta
            name="description"
            content="Learning Beam Academy online high school courses are perfect for Ontario students that require more flexibility in their study schedule."
          />
          <meta
            name="keywords"
            content="Ontario High School Credits Online, High School Credits Courses, Ontario Students."
          />
          <meta
            property="og:title"
            content="Ontario Credit Courses Online - Ontario Students"
          />
          <meta
            property="og:image"
            content="/static/media/logo.358fb4cd.svg"
            alt="International students Canada"
          />
        </MetaTags>
        <Top
          heading={<>Ontario High School Credit Courses</>}
          description="Our High School Credit Courses are perfect for Ontario students that require greater adaptability in their study schedule."
          imageSrc={courseMainImage}
          imageCss={imageCss}
          altContent={"Ontario High School Credit Courses"}
          imageDecoratorBlob={true}
        />
        {/* <hr style={{ width: "80%", margin: "auto", justifyContent: "center" }} /> */}
        <hr style={{ width: "84%", marginLeft: "2.5em" }} />
        {/* <WhyUs /> */}
        <Desc heading={<>Earn High School Credit Courses Online With Us</>} />
        <CoursesOffered heading={<>High School Credit Courses Online </>} />
        <HowDoesItWork heading={<>How Does It Work?</>} />
        <PeopleTestimonial />

        <Calendly />
        <Footer />
      </AnimationRevealPage>
    );
  }
}
export default Canada;
