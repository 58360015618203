import React, { useState, useEffect } from "react";
import { Subheading as SubheadingBase } from "components/common/Headings.js";
import { Link } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { ContentWithPaddingXl } from "components/common/Layouts.js";
import { SectionHeading } from "components/common/Headings.js";

import { SectionDescription } from "components/common/Typography.js";
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 sm:px-4 lg:px-0 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${(props) => props.active && tw`bg-primary-500 text-gray-100`}
`;

const Container = tw.div`relative sm:mb-2   `;

const PlanDurationSwitcher = tw.div`block w-full max-w-xs sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1`;
const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-center md:items-start relative`;
const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed -mx-8 bg-white rounded-t-lg`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;
const Plan = styled.div`
  ${tw`w-full max-w-72 mt-8 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  ${(props) =>
    props.featured &&
    css`
      ${tw`border-0 border-gray-200 shadow-none`}
    `}
`;

const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const Description1 = tw.p`mt-4 text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mb-8`;

function CoursePlan(props) {
  const [activeDurationIndex, setActiveDurationIndex] = useState(0);

  const plans = [
    {
      switcherText: "Citizen",
    },
    {
      switcherText: "International",
    },
  ];

  return (
    <Container>
      <HeaderContainer>
        <PlanDurationSwitcher style={{ borderRadius: "6px" }}>
          {plans.map((plan, index) => (
            <SwitchButton
              active={activeDurationIndex === index}
              key={index}
              onClick={() => setActiveDurationIndex(index)}
              style={{ borderRadius: "6px" }}
            >
              {plan.switcherText}
            </SwitchButton>
          ))}
        </PlanDurationSwitcher>
      </HeaderContainer>
      <PlansContainer>
        {activeDurationIndex === 0 ? (
          <Plan featured={true}>
            <PlanHeader>
              <span className="priceAndDuration">
                <span className="price">
                  <Heading style={{ textAlign: "center" }}>
                    ${props.courseData.fee}
                  </Heading>
                </span>
              </span>
            </PlanHeader>
          </Plan>
        ) : (
          <Plan featured={true}>
            <PlanHeader>
              <span className="priceAndDuration">
                <span className="price">
                  <Heading style={{ textAlign: "center" }}>
                    ${props.courseData.international_fee}
                  </Heading>
                </span>
              </span>
            </PlanHeader>
          </Plan>
        )}
      </PlansContainer>
      <Description1>
        Time Duration {props.courseData.duration} hours
      </Description1>
      <PrimaryButton as="a" href="/joinus">
        Join Us
      </PrimaryButton>
    </Container>
  );
}

export default CoursePlan;
