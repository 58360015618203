import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../common/Headings.js";

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

import aboutImage from "../../images/about.png";
import playImage from "../../images/play.png";
import dot1 from "../../images/oval.png";
import dot2 from "../../images/oval_yellow.png";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

//const Heading = tw(HeadingTitle)`pb-16 w-full`;
const Heading = tw.h2`text-3xl font-bold text-gray-700 text-center`;

const Container = tw.div`relative sm:px-4 md:px-24 lg:px-24 xl:px-24`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-20`;

const HeadingInfoContainer = tw.div`flex flex-col items-center pt-8`;
const HeadingImageContainer = tw.div`pt-12 items-center lg:px-12`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
//const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
//const Title = tw.h4`text-3xl font-bold text-gray-900`;
//const Description = tw.p`mt-2 text-sm leading-loose`;
const Title = tw.h4`text-3xl font-bold text-gray-700 text-center`;
const SubTitle = tw.h4`leading-relaxed font-bold text-lg`;
//const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg leading-relaxed text-secondary-100`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base text-gray-600 mx-12`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

const SingleColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-45 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24 mt-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
export default ({
  watchVideoYoutubeUrl = "https://www.youtube.com/embed/rcaCalyLp2s",
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <>
      <Container>
        <Content>
          <HeadingInfoContainer>
            <Heading>About Us</Heading>
          </HeadingInfoContainer>
          <HeadingInfoContainer>
            {/* <div style={{ width: '100%', paddingLeft: '7em' }}>
						<img src={dot1} style={{ float: 'left', width: '10px' }} />
						<img src={dot1} style={{ float: 'right', width: '10px' }} />
					</div>
					<div style={{ width: '78%' }}>
						<img src={dot2} style={{ float: 'right', width: '15px' }} />
					</div>
					<div style={{ width: '90%' }}>
						<img src={dot2} style={{ float: 'left', width: '20px' }} />
						<img src={dot1} style={{ float: 'right', width: '15px' }} />
					</div>
					<Heading>
						<img src={dot1} style={{ float: 'left' }} />
						About Us
                    </Heading> */}
            <Description>
              <p className="text-center" style={{ textAlign: "center" }}>
                Learning Beam Academy is an online private high school,
                inspected by Ontario Ministry of Education, Canada
                (BSID#889197). We comply with all the regulations and guidelines
                laid down by the Ontario Ministry of Education, Canada. Learning
                Beam Academy offers credit courses from Grade 9-12. We are
                authorized to grant credits to both domestic and international
                students that enable them to get enrolled into globally
                recognized colleges/ universities.
              </p>
              {/* <p className="text-center" style={{ textAlign: 'center', marginTop: '10px' }}>We offer globally recognized Ontario credit courses from grade 9 to grade 12 through our innovative online learning platform. We deliver OSSD courses that not only meet but extend beyond the curriculum requirements of the Ontario Ministry of Education to prepare students for post-secondary education.
</p> */}
            </Description>
          </HeadingInfoContainer>
          <HeadingImageContainer
            style={{ position: "relative" }}
            onClick={toggleModal}
          >
            <img src={aboutImage} alt="learning" />
            <img
              src={playImage}
              style={{
                position: "absolute",
                width: "96px",
                height: "96px",
                left: "50%",
                top: "50%",
                marginLeft: "-48px",
                marginTop: "-48px",
              }}
              alt="learning"
            />
          </HeadingImageContainer>
          <SvgDotPattern1 />
          <SvgDotPattern2 />
          <StyledModal
            closeTimeoutMS={300}
            className="mainHeroModal"
            isOpen={modalIsOpen}
            onRequestClose={toggleModal}
            shouldCloseOnOverlayClick={true}
          >
            <CloseModalButton onClick={toggleModal}>
              <CloseIcon tw="w-6 h-6" />
            </CloseModalButton>
            <div className="content">
              <ResponsiveVideoEmbed url={watchVideoYoutubeUrl} tw="w-full" />
            </div>
          </StyledModal>
        </Content>
      </Container>
    </>
  );
};
