import React, { Component } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithVerticalPadding } from "components/common/Layouts.js";
import Sphere from "../../images/sphere.png";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/dot-pattern.svg"


import { SectionHeading } from "components/common/Headings.js";
import axios from "axios";
import "../../config.js";
const qs = require('querystring');
const Heading = tw(SectionHeading)`text-left text-primary-900 leading-snug xl:text-6xl`;

const Title = tw.h4`sm:text-xl md:text-2xl text-3xl font-bold text-gray-700 text-left pt-4 pb-4`;
const SubTitle = tw.h4`leading-relaxed font-bold text-sm lg:text-base pt-4`;
const SubTitle1 = tw.h4`leading-relaxed text-sm lg:text-base pt-4`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;


const Row = tw.div`flex flex-wrap overflow-hidden`;
const Column = tw.div``;
const TextColumn = tw(Column)`w-full overflow-hidden`;
const FeatureList = tw.ul`mt-4 leading-tight`;
const Feature = tw.li`flex items-center`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 mx-12`;
const Paragraph1 = tw.p`my-2 lg:my-2 text-sm lg:text-base text-gray-600 mx-4`;


const required = ["Spreadsheet software (e.g. Microsoft ExcelTM, Mac NumbersTM, or equivalent)", "Word processing software (e.g. Microsoft WordTM, Mac PagesTM, or equivalent)"];
const provided = ["This course is entirely online and does not require or rely on any textbook."];

class Considerations extends Component {
    constructor() {
        super();
        this.state = {
            resourcesProvided: [],
            resourcesRequired: [],
        }
    }
    componentDidMount() {
        // this.setState({
        //     resourcesProvided: this.props.courseData.getResourcesProvided,
        //     resourcesRequired: this.props.courseData.getResourcesRequired,
        // });
        // this.state.resourcesProvided = this.props.courseData.getResourcesProvided;
    }
    // getCourseData = () => {
    //     axios.get(global.config.main.api_url + '?' + this.props.data)
    //         .then(response => {
    //             this.setState({
    //                 resourcesProvided: response.data.getResourcesProvided,
    //                 resourcesRequired: response.data.getResourcesRequired,
    //             });
    //             this.state.resourcesProvided = response.data.getResourcesProvided;
    //         });
    // }
    render() {

        return (
            <Container>
                <Row style={{ "paddingTop": "0" }} >
                    <TextColumn>
                        <Title>Considerations For Program Planning</Title>
                        <SubTitle>Instructional Approaches</SubTitle>
                        <SubTitle1>Teachers in the school are expected to:</SubTitle1>
                        <FeatureList>
                            <Feature key={"1"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Clarify the purpose for learning</Paragraph1>
                            </Feature>
                            <Feature key={"2"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Help students activate prior knowledge</Paragraph1>
                            </Feature>
                            <Feature key={"3"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Differentiate instruction for individual students and small groups according to need</Paragraph1>
                            </Feature>
                            <Feature key={"4"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Explicitly teach and model learning strategies</Paragraph1>
                            </Feature>
                            <Feature key={"5"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Use various (auditory, visual, etc) methods of teaching</Paragraph1>
                            </Feature>
                            <Feature key={"6"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Encourage students to talk through their thinking and learning processes</Paragraph1>
                            </Feature>
                            <Feature key={"7"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Provide many opportunities for students to practice and apply their developing knowledge and skills</Paragraph1>
                            </Feature>
                            <Feature key={"8"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Apply effective teaching approaches involve students in the use of higher-level thinking skill</Paragraph1>
                            </Feature>
                            <Feature key={"9"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Encourage students to look beyond the literal meaning of texts</Paragraph1>
                            </Feature>
                        </FeatureList>

                        <SubTitle>Planning for students with special needs</SubTitle>
                        <SubTitle1>Teachers use a variety of instructional and learning strategies best suited to the particular type of learning. Special needs of students are considered and lessons are modified accordingly. Based on their individual needs, students have opportunities to learn in a variety of ways:</SubTitle1>
                        <FeatureList>
                            <Feature key={"1"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Individually, one-on-one sessions</Paragraph1>
                            </Feature>
                            <Feature key={"2"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Cooperatively, small group sessions</Paragraph1>
                            </Feature>
                            <Feature key={"3"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Independently with teacher direction</Paragraph1>
                            </Feature>
                            <Feature key={"4"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Through investigation involving hands-on experience</Paragraph1>
                            </Feature>
                            <Feature key={"5"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Through examples followed by practice</Paragraph1>
                            </Feature>
                            <Feature key={"6"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>By using concrete learning tools such as models, videos, equipment, charts.</Paragraph1>
                            </Feature>
                            <Feature key={"7"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>By encouraging students to gain experience with varied and interesting applications of then knowledge. Rich contexts for learning open the door for students to see the “big ideas” of the subject.</Paragraph1>
                            </Feature>
                        </FeatureList>

                        <SubTitle>Program Considerations for English Language Learners</SubTitle>
                        <SubTitle1>Teachers must incorporate appropriate strategies for instruction and assessment to facilitate the success of the English language learners in their classrooms. These strategies include:</SubTitle1>
                        <FeatureList>
                            <Feature key={"1"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Use of a variety of instructional strategies (e.g., extensive use of visual cues, graphic organizers, scaffolding; previewing of textbooks; pre-teaching of key vocabulary; peer tutoring; strategic use of students’ first languages)</Paragraph1>
                            </Feature>
                            <Feature key={"2"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Use of a variety of learning resources (e.g., visual material, simplified text, bilingual dictionaries, and materials that reflect cultural diversity)</Paragraph1>
                            </Feature>
                            <Feature key={"3"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Use of assessment accommodations (e.g., granting of extra time; use of oral interviews, demonstrations or visual representations, or tasks requiring completion of graphic organizers and close sentences instead of essay questions and other assessment tasks that depend heavily on proficiency in English).</Paragraph1>
                            </Feature>
                        </FeatureList>

                        {/* ****************** Start ***************** */}
                        <SubTitle>Environmental Education</SubTitle>
                        <SubTitle1>Educators in every discipline of Learning Beam Academy aim to include environmental education in their classroom experiences. This can be observed in the overall expectations of the courses. Additionally, our teachers strive to:</SubTitle1>
                        <FeatureList>
                            <Feature key={"1"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Use natural and human-built environments as sites for discovery, problem-solving, and active learning, as well as first-hand experiences that put students in touch with nature.</Paragraph1>
                            </Feature>
                            <Feature key={"2"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Provide assignments that allow students to determine how their course subject matter can be used for the betterment of the environment.</Paragraph1>
                            </Feature>
                            <Feature key={"3"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Evaluate positive and negative consequences of an invention, discovery, project or experiment to the environment.</Paragraph1>
                            </Feature>
                        </FeatureList>
                        {/* ****************** End ***************** */}

                        {/* ****************** Start ***************** */}
                        <SubTitle>Equity and Inclusive Education</SubTitle>
                        <SubTitle1>Classroom experiences at Learning Beam Academy aim to inspire students to become a knowledgeable and a model Canadian citizen. Our teachers ensure that all students feel comfortable and included in their classrooms. Learning resources reflect students’ interests, backgrounds, cultures, and experiences. Learning materials:</SubTitle1>
                        <FeatureList>
                            <Feature key={"1"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Involve protagonists of both sexes from a wide variety of backgrounds</Paragraph1>
                            </Feature>
                            <Feature key={"2"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Reflect the diversity of Canadian and world cultures, including those of contemporary First Nations, Métis, and Inuit peoples</Paragraph1>
                            </Feature>
                            <Feature key={"3"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Métis, and Inuit peoples provide opportunities for students to explore issues relating to their self-identity</Paragraph1>
                            </Feature>
                            <Feature key={"4"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1> Make students aware of the historical, cultural, and political contexts for both the traditional and non-traditional gender and social roles represented in the materials they are studying.</Paragraph1>
                            </Feature>

                        </FeatureList>
                        {/* ****************** End ***************** */}

                        {/* ****************** Start ***************** */}
                        <SubTitle>Financial Literacy Education</SubTitle>
                        <SubTitle1>Financial Literacy is implicitly or explicitly included in many of our courses such as mathematics, sciences, civics and careers, history, Canadian and World studies, business studies, economics, accounting, and many more. Our teachers aim to develop students’ knowledge and skills to make responsible economic and financial decisions with confidence. Regarding financial literacy, our teachers’ goals are to:</SubTitle1>
                        <FeatureList>
                            <Feature key={"1"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Teach students about how to make informed decisions.</Paragraph1>
                            </Feature>
                            <Feature key={"2"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Provide mock-experiences where students are able to make financial decisions by judging various factors.</Paragraph1>
                            </Feature>
                            <Feature key={"3"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Allow students to evaluate the costs and benefits of a certain situation such as a research project, science experiment, political decision, or other matters pertaining to the subject of the course.</Paragraph1>
                            </Feature>
                            <Feature key={"4"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Provide students an opportunity to evaluate and discuss how certain scientific, social, political, mathematical, or financial decision could affect their family, community, Canada and the world.</Paragraph1>
                            </Feature>

                        </FeatureList>
                        {/* ****************** End ***************** */}

                        {/* ****************** Start ***************** */}
                        <SubTitle>Literacy and Inquiry/Research Skills</SubTitle>
                        <SubTitle1>The school emphasizes the importance of the following:</SubTitle1>
                        <FeatureList>
                            <Feature key={"1"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Using clear, concise communication in the classroom involving the use of diagrams, charts, tables, and graphs emphasizing students’ ability to interpret and use graphic texts.</Paragraph1>
                            </Feature>
                            <Feature key={"2"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Acquiring the skills to locate relevant information from a variety of sources, such as books, newspapers, dictionaries, encyclopedias, interviews, videos, and the Internet.</Paragraph1>
                            </Feature>
                            <Feature key={"3"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>learning that all sources of information have a particular point of view learning that the recipient of the information has a responsibility to evaluate it, determine its validity and relevance, and use it in appropriate ways.</Paragraph1>
                            </Feature>
                        </FeatureList>
                        {/* ****************** End ***************** */}

                        {/* ****************** Start ***************** */}
                        <SubTitle>Role of Technology</SubTitle>
                        <SubTitle1>Information and communications technologies (ICT) tools used in many ways:</SubTitle1>
                        <FeatureList>
                            <Feature key={"1"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Students use multimedia resources, databases, Internet websites, digital cameras, and word-processing programs.</Paragraph1>
                            </Feature>
                            <Feature key={"2"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>They use technology to collect, organize, and sort the data they gather and to write, edit, and present reports on their findings.</Paragraph1>
                            </Feature>
                            <Feature key={"3"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Students are encouraged to use ICT to support and communicate their learning.</Paragraph1>
                            </Feature>
                            <Feature key={"4"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>For example, students working individually or in Groups can use computer technology and/or Internet websites to gain access to museums and archives in Canada and around the world.</Paragraph1>
                            </Feature>
                            <Feature key={"5"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Students use digital cameras and projectors to design and present the results of their research to their classmates.</Paragraph1>
                            </Feature>
                            <Feature key={"6"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>The school plans to use ICT to connect students to other schools and to bring the global community into the classroom.</Paragraph1>
                            </Feature>
                            <Feature key={"7"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Students are made aware of issues of Internet privacy, safety, and responsible use, as well as of the potential for abuse of this technology, particularly when it is used to promote hatred.</Paragraph1>
                            </Feature>
                        </FeatureList>
                        {/* ****************** End ***************** */}

                        {/* ****************** Start ***************** */}
                        <SubTitle>Career Education</SubTitle>
                        <SubTitle1>Students are given opportunities in related courses to develop career-related skills by:</SubTitle1>
                        <FeatureList>
                            <Feature key={"1"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Applying their skills to work-related situations.</Paragraph1>
                            </Feature>
                            <Feature key={"2"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Exploring educational and career options.</Paragraph1>
                            </Feature>
                            <Feature key={"3"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Developing research skills.</Paragraph1>
                            </Feature>
                            <Feature key={"4"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Practicing expository writing.</Paragraph1>
                            </Feature>
                            <Feature key={"5"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Learning strategies for understanding informational reading material.</Paragraph1>
                            </Feature>
                            <Feature key={"6"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>The school plans to use ICT to connect students to other schools and to bring the global community into the classroom.</Paragraph1>
                            </Feature>
                            <Feature key={"7"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Making oral presentations.</Paragraph1>
                            </Feature>
                            <Feature key={"8"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Working in small groups with classmates to help students express themselves confidently and work cooperatively with others.</Paragraph1>
                            </Feature>
                        </FeatureList>
                        {/* ****************** End ***************** */}

                        {/* ****************** Start ***************** */}
                        <SubTitle>Healthy and Safety</SubTitle>
                        <SubTitle1>Health and safety of our students are taken very seriously at Learning Beam Academy. The following steps are taken to ensure that students feel safe and healthy in out classrooms:</SubTitle1>
                        <FeatureList>
                            <Feature key={"1"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Teachers are familiarized with relevant sections of the provincial Education Act, the Occupational Health and Safety Act, and the federal Hazardous Products Act, as well as with the safety procedures mandated by the Before taking part in community-based learning experiences, instructions are provided regarding safe participation.</Paragraph1>
                            </Feature>
                            <Feature key={"2"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>If lessons deal with hazardous materials, such as chemicals, instructions are provided regarding safe practices, need for personal protective equipment (goggles, gloves, aprons, etc), use of safety equipment, correct handling of materials and equipment.</Paragraph1>
                            </Feature>
                            <Feature key={"3"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Provisions of Occupational Health and Safety Act and Workplace Safety and Insurance Act are reviewed as needed.</Paragraph1>
                            </Feature>
                            <Feature key={"4"}>
                                <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                                <Paragraph1>Appropriate Fire Safety drills and Lockdown Drills are conducted regularly.</Paragraph1>
                            </Feature>
                        </FeatureList>
                        {/* ****************** End ***************** */}

                        {/* ****************** Start ***************** */}
                        <SubTitle>Academic Honesty</SubTitle>
                        <SubTitle1>Unintentional plagiarism may consist of quoting or paraphrasing a source without reference, stating information without citation because the student thought that it is common knowledge, etc. Unintentional plagiarism will be addressed with progressive discipline. For their first unintentional plagiarism, the teacher will thoroughly explain to the student about citations, and the student will be asked to rewrite the assignment. However, if the student commits this type of plagiarism again, it may not be taken as unintentional and the teacher reserves the right to deduct marks as per our plagiarism policy.</SubTitle1>
                        <SubTitle1>Students who present the work of others as their own are guilty of plagiarism and will receive a mark of zero for the assignment. Students who are guilty of cheating on tests or examinations will receive a mark of zero on the test or examination. If a student is caught plagiarizing an assignment or cheating on a test/exam for the second time, it will be permanently noted in the student’s school records.</SubTitle1>
                        {/* ****************** End ***************** */}

                        {/* ****************** Start ***************** */}
                        <SubTitle>Late Assignments</SubTitle>
                        <SubTitle1>Students are responsible for providing evidence of their achievement of the overall expectations within the time frame specified by the teacher, and in a form approved by the teacher. There are consequences for not completing assignments for evaluation or for submitting those assignments late. These consequences are clearly informed to the student at the beginning of the course and also prior to assigning an assignment. Teachers will also give multiple reminders to students about the due date. There is a 24-hour grace period given for each late assignment. After that, teachers are allowed to deduct 10% from the final mark of the assignment for each day the assignment is late.</SubTitle1>
                        {/* ****************** End ***************** */}
                    </TextColumn>

                </Row>
            </Container>
        )
    }
};
export default (Considerations);
