import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Modal from "react-modal";
import { BrowserRouter } from "react-router-dom";
import MessengerCustomerChat from "react-messenger-customer-chat";

import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

Modal.setAppElement("#root");

ReactDOM.hydrate(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <App />
      <MessengerCustomerChat pageId="101612661497471" />
    </BrowserRouter>
  </QueryClientProvider>,
  document.getElementById("root")
);
