import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../common/Header.js";

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

//import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
//import { ReactComponent as PlayIcon1 } from "../../images/chevron-right.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import DesignIllustration from "../../images/design-illustration.svg";
import { ReactComponent as CheckboxIcon } from "../../images/checkbox-circle.svg";


//import usaImage from "../../images/us.png";
//import canadaImage from "../../images/canada.png";


const Container = tw.div`relative px-5 md:px-12`;
// No Padding on Left and Right
//const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
// Paddding on Left & Right
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-4`;
//const LeftColumn = tw.div`relative lg:w-6/12 xl:pr-12 flex-shrink-0 text-center lg:text-left`;
const LeftColumn = tw.div`relative lg:w-6/12 xl:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;

const Heading = tw.h2`text-secondary-450 text-3xl lg:text-4xl xl:text-5xl font-medium leading-tight max-w-4xl font-serif`;
const Paragraph = tw.p`my-5 xl:my-4 text-sm lg:text-base font-normal text-secondary-250 mx-auto lg:mx-0`;


const SubParagraph = tw.p`my-5 xl:my-4 text-sm font-normal text-secondary-250 mx-auto lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;

const PrimaryButton = tw.button`font-bold px-8 lg:px-8 py-3 rounded bg-primary-500 text-white hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

const SvgDotPattern1 = tw(
  SvgDecoratorBlob2
)`absolute top-0 left-0 transform -translate-x-20 rotate-45 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24 mt-16`;
const SvgDotPattern2 = tw(
  SvgDecoratorBlob2
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

const HeadingContainer = tw.div``;

const Title = tw.h4`text-3xl font-normal text-secondary-450 text-left font-serif`;

const CountryTitle = tw.h5`text-2xl font-normal text-secondary-450 text-left font-serif mt-6`;

const CountryFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm text-left`}
  .feature {
    ${tw`mt-3 first:mt-0 font-normal text-secondary-250`}
  }
`;

const TwoColumnContainer = styled.div`
${ tw`flex flex-wrap py-1 justify-start m-0 pb-8`}
`;

const Column = styled.div`
  ${tw`my-1 px-1 w-full m-4 lg:m-2 overflow-hidden md:w-5/12 lg:w-5/12 xl:w-5/12`}
`;

const Card = styled.a`


  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  // .link {
  //   ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
  //   .icon {
  //     ${tw`ml-2 w-4`}
  //   }
  }
`;

const PlanFeatures = styled.ul`
  ${tw`mt-4 flex-1 lg:-mx-6 -mx-6 sm:-mx-10 py-10 px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 text-primary-500 flex-shrink-0`}
    }
    .text {
      ${tw`font-normal text-sm text-secondary-250 tracking-wide ml-3`}
    }
  }
`;

const Feature = tw.div`mt-8 flex items-start flex-row`;
const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block text-center rounded-full p-6 flex-shrink-0 bg-blue-100`}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-blue-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }
  .img {
    ${tw`w-8 h-8`}
  }
`;

const FeatureText = tw.div`mt-2 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold  text-base text-secondary-450 text-left sm:ml-2`;
const FeatureDescription = tw.div`mt-1 text-xs text-secondary-250 text-left sm:ml-2`;

//const Paragraph = tw.p`my-5 xl:my-6 text-sm lg:text-base font-normal text-secondary-250 max-w-lg mx-auto lg:mx-0`;

export default ({

  cardsRow1 = [
    {
      title: "Academic Training Modules",
      description: "It assesses whether the candidate can study undergraduate or postgraduate courses in the English medium. The admission to academic programs of overseas institutions is based on the results of these modules.",
      url: ""
    },
    {
      title: "General Training Modules",
      description: "Though, this test does not test the full range of formal language skills needed in academic environments, it emphasizes on survival skills of a candidate in broader areas of social and educational scenarios.",
      url: ""
    },
  ],
  heading = "Our Team",
  description = "Our team help students learn both formats of the IELTS",
  sub_description = "There are two versions of the IELTS exam, the Academic Module and the General Training module. Canadian universities and professional bodies require applicants to sit for the Academic module. The General Training module is for those who are planning to work or take training programs, or for individuals who are planning to migrate to Canada. Candidates are tested in the four core skills of Listening, Speaking, Reading and Writing. Candidates receive a score for each of the four sections of the exam as well as an overall score.",


  heading_1 = "Scores for School and Work",
  description_1 = "Our team help students learn both formats of the IELTS",
  sub_description_1 = "The scores listed below are some examples of requirements for college, university and professional programs in the Greater Toronto Area Citizenship and Immigration Canada (CIC) accepts IELTS General Training scores as proof of English proficiency. Applying for permanent residence (PR) status usually requires band scores of 6.",

  primaryButtonText = "Register",
  primaryButtonUrl = "#",
  watchVideoButtonText = "Get a Free Demo",
  watchVideoYoutubeUrl = "https://www.youtube.com/embed/_GuOjXYl5ew",
  imageSrc = DesignIllustration,
  imageCss = null,
  imageDecoratorBlob = false,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const features = ["60 Templates", "8 Landing Pages", "22 Internal Pages", "Priority Assistance", "Lifetime Updates"];

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <>
      <Container>
        <HeadingContainer>
          <Title>{heading}</Title>
        </HeadingContainer>
        <Paragraph>{description}</Paragraph>
        <SubParagraph>{sub_description}</SubParagraph>


        <TwoColumnContainer>
          {cardsRow1.map((card, i) => (
            <Column style={{
              "borderRadius": "10px", "backgroundColor": "#FFFFFF", "boxShadow": "0 17px 34px 0 rgba(182,182,182,0.2)", "height": "180px", marginTop: "1em"
            }} key={i}>
              <Card href={card.url
              } >

                <Feature key={i}>

                  <FeatureText>
                    <FeatureHeading>{card.title}</FeatureHeading>
                    <FeatureDescription>{card.description}</FeatureDescription>
                  </FeatureText>
                </Feature>
              </Card>
            </Column>
          ))}
        </TwoColumnContainer>


        <HeadingContainer>
          <Title>{heading_1}</Title>
        </HeadingContainer>
        <SubParagraph>{sub_description_1}</SubParagraph>
        <PlanFeatures>
          <li className="feature">
            <CheckboxIcon className="icon" />
            <span className="text">{"College Diploma Programs: overall 6 (no band below 5.5)"}</span>
          </li>
          <li className="feature">
            <CheckboxIcon className="icon" />
            <span className="text">{"Undergraduate Studies: overall 6.5 (no band below 6)"}</span>
          </li>
          <li className="feature">
            <CheckboxIcon className="icon" />
            <span className="text">{"Postgraduate and Professional Degrees: typically 7 or higher on all sections"}</span>
          </li>
          <li className="feature">
            <CheckboxIcon className="icon" />
            <span className="text">{"Admission into Professional Bodies: typically 7 overall or higher"}</span>
          </li>
        </PlanFeatures>
        {/* <TwoColumn style={{ paddingBottom: '0' }}>
                    <LeftColumn>
                        <IllustrationContainer>
                            <img
                                src={usaImage}
                                alt="USA"
                            />
                        </IllustrationContainer>
                        <CountryTitle>{"Study in US"}</CountryTitle>
                        <CountryFeatures>
                            {features.map((feature, index) => (
                                <span key={index} className="feature">
                                    {feature}
                                </span>
                            ))}
                        </CountryFeatures>
                    </LeftColumn>
                    <RightColumn>
                        <IllustrationContainer>
                            <img
                                src={canadaImage}
                                alt="Hero"
                            />
                        </IllustrationContainer>
                        <CountryTitle>{"Study in Canada"}</CountryTitle>
                        <CountryFeatures>
                            {features.map((feature, index) => (
                                <span key={index} className="feature">
                                    {feature}
                                </span>
                            ))}
                        </CountryFeatures>
                    </RightColumn>
                </TwoColumn> */}
        {/* <SvgDotPattern1 />
        <SvgDotPattern2 /> */}
        <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div className="content">
            <ResponsiveVideoEmbed url={watchVideoYoutubeUrl} tw="w-full" />
          </div>
        </StyledModal>
      </Container >
    </>
  );
};
