import React, { useState } from "react";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/common/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/common/Headings.js";

import { InlineWidget, PopupWidget, PopupButton } from "react-calendly";
import "slick-carousel/slick/slick.css";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-primary-200 text-gray-100`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;


const Title = tw.h4`text-3xl font-bold text-white text-center`;
export default ({
  subheading = "",
  heading = "Book Free Consultation",

}) => {
  const [sliderRef, setSliderRef] = useState(null)

  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Title>{heading}</Title>
        </HeadingContainer>
        <InlineWidget styles={{
          height: '700px', margin: '20px'
        }} url="https://learningbeamacademy.zohobookings.com/portal-embed#/customer/learningbeamacademy" />
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
