import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
//import { Container, ContentWithPaddingXl } from "components/common/Layouts.js";
//import { ContentWithPaddingXl } from "components/common/Layouts.js";
import { SectionHeading } from "components/common/Headings.js";
//import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as svg220 } from "images/220.svg";
import { ReactComponent as CheckboxIcon } from "../../images/checkbox-circle.svg";

//import svg220 from "../../images/220.svg";

export const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto pt-8 lg:pt-20`;

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-4 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;


const Container = tw.div`relative md:px-10`
//const Container = tw.div`relative`


const DecoratorBlob2 = styled(svg220)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-80 w-80 transform translate-x-2/3 text-primary-500`}
`;
const Title = tw.h3`text-3xl font-bold font-black text-center`;


const HeadingContainer = tw.div`mb-12`;

const SubParagraph = tw.p`mt-12 mb-8 text-base text-secondary-200 mx-auto lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-2`;

const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;

const FeatureText = tw.div`md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`mt-4 text-sm lg:text-base font-semibold text-secondary-200 text-left`;
const FeatureDescription = tw.p`mt-2 text-sm lg:text-base text-gray-600 mx-auto lg:mx-0`;
const FeatureSubDescription = tw.div`mt-1 text-sm text-secondary-200 text-left`;
const PlanFeatures = styled.ul`
  ${tw`flex-1 lg:-mx-6 -mx-6 sm:-mx-10 py-10 px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 text-primary-500 flex-shrink-0`}
    }
    .text {
      ${tw`font-normal text-sm text-secondary-250 tracking-wide ml-3`}
    }
  }
`;

export default ({
  heading = "",
}) => {

  return (
    <Container>
      <DecoratorBlob2 />
      <ContentWithPaddingXl className={"pt-4 pb-2"}>

        <HeadingContainer>
          {/* {subheading && <Subheading>{subheading}</Subheading>} */}
          <Title>{heading}</Title>
        </HeadingContainer>
        <FeatureDescription>Our credits courses are accepted by all public and private high schools, universities & colleges. Learning Beam Academy is a private asynchronous Ministry inspected high school offering the Ontario Secondary School Diploma (OSSD) and credit courses. Our online high school courses are 100% online. They’re flexible enough to meet your busy schedule, so you can take classes on your own time. And they’re convenient enough to allow you to finish at your own pace!</FeatureDescription>

        <PlanFeatures>
          <li className="feature">
            <CheckboxIcon className="icon" />
            <span className="text">{"Register"}</span>
          </li>
          <li className="feature">
            <CheckboxIcon className="icon" />
            <span className="text">{"Submit required documents"}</span>
          </li>
          <li className="feature">
            <CheckboxIcon className="icon" />
            <span className="text">{"Receive Course information"}</span>
          </li>
        </PlanFeatures>
        {/* <FeatureDescription>Our courses are designed to help students of all ages reach their educational goals faster. Our flexible Ontario high school curriculum is student-centric and offers assistance for those who need it. You don’t have to worry about finding time to travel to a physical location, as our online lessons allow you to learn from the comfort of your own home.</FeatureDescription> */}
        {/* <FeatureHeading>1. Upgrade Your Marks For University or College — With Confidence</FeatureHeading>
        <FeatureDescription>Many Ontario high school students register with LBA as they search for online high school courses with flexibility. We offer a range of compulsory and elective courses for grades 9 through 12 in subject like english, science and math. We also offer upgrade courses which are courses you need to repeat in hopes or earning a higher mark for post-secondary admissions. These courses generally have a reduced workload. Connect with us to learn more.</FeatureDescription>

        <FeatureHeading>2. Finish Your Diploma — Anytime</FeatureHeading>
        <FeatureDescription>Some students enrol with LBA to earn a high school credit course in which they were previously unsuccessful or are missing. With our supportive Ontario Certified Teachers (OCT) and 24/7 online tutoring service we will do our best to help you succeed and get the credit(s) you need.</FeatureDescription>

        <FeatureHeading>3. Fast-Track — Conveniently</FeatureHeading>
        <FeatureDescription>Students also take courses with LBA to free up their timetable for the upcoming school year, to avoid timetable conflicts, or to earn a credit not available anywhere else. Learn more here. If you’re ready to enroll into our Ontario accredited high school courses please begin by visiting our How Does it Work? page. Included in the fee are all materials, instruction by an Ontario Certified Teacher, tests, modules, simulations examinations, and access to an online tutor which is a 24/7 on-demand and completely online service.</FeatureDescription> */}

      </ContentWithPaddingXl>

    </Container>
  );
};







