import React, { useState, Component } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/common/Layouts.js";
import { SectionHeading } from "components/common/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";

import CourseConsiderations from "components/course/Considerations.js";

import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import CourseRequirement from "components/course/Requirement.js";
import Strategy from "components/common/Strategy.js";
//import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as CheckboxIcon } from "../../images/checkbox-circle.svg";
//import CoursesDetailsFaq from "components/course/Faq.js";
import Sphere from "../../images/sphere.png";
import "../../config.js";
import axios from "axios";
//const Quote = tw.h5`font-normal text-center md:text-left text-sm text-xl lg:text-base xl:text-lg p-4`;
const Quote = tw.h4`font-semibold text-xs md:text-base lg:text-base text-secondary-250 sm:p-2 p-4 text-center`;
const SubTitle = tw.h4`leading-relaxed text-sm lg:text-base font-normal text-secondary-250 md:text-left pl-4`;
const SubTitleCenter = tw.h4`leading-relaxed text-sm lg:text-base text-center`;
const SubTitleTime = tw.h4`leading-relaxed text-sm lg:text-base font-normal text-secondary-250 text-center`;
const Title = tw.h4`font-serif sm:text-lg md:text-xl lg:text-2xl font-normal text-secondary-450 text-left`;


const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-16 px-5 lg:px-12`;

const DivContainer = tw.div`mt-12 first:mt-0 mb-4`;

const qs = require('querystring');
const Row = tw.div`flex flex-wrap overflow-hidden`;
const Column = tw.div``;
const TextColumn = tw(Column)`w-full overflow-hidden`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl`;

const feature = tw`flex items-start mt-6 first:mt-0`;
const icon = tw`w-6 h-6 text-primary-500 flex-shrink-0`;


const PlanFeatures1 = styled.ul`
  ${tw`flex-1 lg:-mx-10 -mx-10 sm:-mx-10 px-6 sm:px-10 lg:p-12 xl:-mx-10 xl:p-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 text-primary-500 flex-shrink-0`}
    }
    .text {
      ${tw`font-normal text-sm text-secondary-250 tracking-wide ml-3`}
    }
  }
`;

const PlanFeatures = styled.div`
  ${tw`flex-1 lg:-mx-10 -mx-10 sm:-mx-10 px-6 sm:px-10 lg:p-12 xl:-mx-10 xl:p-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 text-primary-500 flex-shrink-0`}
    }
    .text {
      ${tw`font-normal text-sm text-secondary-250 tracking-wide ml-3`}
    }
  }
`;

const PageTitle = tw.h4`text-3xl font-bold text-gray-700 text-center mt-4`;

const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base text-gray-600 mx-4`;
const Paragraph1 = tw.p`my-2 lg:my-2 text-sm lg:text-base text-gray-600 mx-4`;
const TwoColumn = tw.div`flex flex-wrap -mx-1 overflow-hidden`;
const LeftColumn = tw.div`my-1 px-1 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2`;
const RightColumn = tw.div`my-1 px-1 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2`;
const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row mt-8`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 mb-8 xl:mt-0`;
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;


const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;
const tabs = {
	Description: [],
	Requirement: [],
	Expectation: [],
}
//const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const PrimaryButton = tw.button`font-bold sm:text-sm md:text-base sm:px-6 md:px-8 py-3 rounded bg-primary-500 text-white hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
//const Title = tw.h4`text-3xl font-bold text-gray-900`;
class CourseDetails extends Component {
	constructor() {
		super();
		document.title = "Course - Learning Beam";
		this.state = {
			course_code: '',
			data: [],
			description: '',
			unitCourseDetails: [],
			finalCourseDetail: [],
			resourcesRequired: [],
			expectation: [],
			strategies: [],
			strategiesPoint: [],
			dummyValue: '',
			MyComponent: '',
			total_hours: '',
			course_description: '',
			strategyData: [],
			subject: "DUMMY",
			getCourseSubjectsData: [],
			getCourseSubjects: [],
			courseData: '',
			dummyRecord: '',
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		console.log("In componentDidMount - Details")
		console.log(this.props.data);

		this.setState({
			data: this.props.data,
			getCourseSubjects: this.props.data.getCourseSubjects,
			getCourseSubjectsData: this.props.data.getCourseSubjectsData,
			course_description: "",//response.data.getCourseDesc.course_desc,
		});
		this.state.data = this.props.data;
		this.state.dummyValue = "";
		//console.log(global.config.main.tuition_api_url);
		//this.getCourseData();
	}

	getCourseData = () => {
		axios.get(global.config.main.tuition_api_url + '?' + this.props.data)
			.then(response => {
				console.log(response.data);
				this.setState({
					data: response.data,
					getCourseSubjects: response.data.getCourseSubjects,
					getCourseSubjectsData: response.data.getCourseSubjectsData,
					course_description: "",//response.data.getCourseDesc.course_desc,
				});
				this.state.data = response.data;
				this.state.dummyValue = "";
			});
	}

	setSubject(subject) {
		this.state.subject = subject;
	}

	scrollToBottom(arg) {
		var component_id = document.getElementById(arg.tabName);
		//component_id.scrollIntoView({behavior: "smooth"});
		//window.scrollTo(0, 2700);
		document.getElementById(arg.tabName).scrollIntoView();
	}
	render() {
		const tabsKeys = Object.keys(tabs);
		const [activeTab, setActiveTab] = (tabsKeys[0]);
		return (
			<Container>
				<SingleColumn>
					{/*<center>
						<HeaderRow style={{ display: 'inline-block' }}>
							<TabsControl style={{ marginLeft: '0' }}>
								{Object.keys(tabs).map((tabName, index) => (
									<TabControl key={index} name={tabName}>
										{tabName}
									</TabControl>
								))}
							</TabsControl>
						</HeaderRow>
					</center>*/}
					{this.state.getCourseSubjects != false ?
						<>
							{this.state.getCourseSubjects.map((data, index) => (
								<DivContainer>
									<thead>
										<tr>
											<th style={{ "width": "100%" }}>
												<Title>{data.subject}</Title>
											</th>
											<th style={{ "white-space": "nowrap" }}>
												<PrimaryButton as="a" href="/joinus">Join Us</PrimaryButton>
											</th>
										</tr>
									</thead>
									{/* <Paragraph>
										{this.state.course_description}
									</Paragraph> */}
									<table style={{ tableLayout: 'fixed', width: "100%", marginTop: "20px" }}>
										<thead style={{ backgroundColor: '#F9F9F9' }}>
											<tr className="trBorder">
												<th className="courseDetailTableTdFirstBorder" style={{ "width": "60%" }}>
													<Quote>
														Subject Content
													</Quote>
												</th>
												{/* <th className="courseDetailTableTdFirstBorder">
													<Quote>
														Duration
													</Quote>
												</th>
												<th>
													<Quote>
														Monthly Fees
													</Quote>
												</th> */}
											</tr>
										</thead>
										<tbody>

											<tr className="trBorder">
												<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
													{this.state.getCourseSubjectsData.map((subjectData, index) => (
														subjectData.subject == data.subject ?



															<span >&#10004;&nbsp;{subjectData.course_heading}&nbsp;&nbsp;&nbsp;&nbsp;    </span>



															:
															""
													))}
												</td>
											</tr>

										</tbody>
									</table>
								</DivContainer>

							))}
						</>
						: ""
					}

					{/* <CourseConsiderations /> */}

				</SingleColumn >
			</Container >
		)
	}
}
export default CourseDetails
