import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading, Subheading as SubheadingBase } from "../common/Headings.js";
import DesignIllustration from "../../images/design-illustration.svg";


const LeftContainer = tw.div`lg:w-2/3 px-5 md:px-10`;
const RightContainer = styled.div`${tw`mt-2 lg:mt-0 lg:w-1/3`}`;

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24 px-5 md:px-10`;
const LeftColumn = tw.div`my-5 w-full overflow-hidden md:w-1/2 lg:w-1/2 xl:w-1/2`;
const RightColumn = tw.div`my-5 w-full overflow-hidden md:w-1/2 lg:w-1/2 xl:w-1/2`;
const Heading = tw.div`mt-4 text-sm lg:text-lg font-bold`;
const Container = tw.div`relative mt-8 mb-8`;

const HeadingInfoContainer = tw.div`flex flex-col items-center pt-8`;
const HeadingImageContainer = tw.div`pt-12 items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
	tw`mt-24 md:flex justify-center items-center`,
	props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
	`background-image: url("${props.imageSrc}");`,
	tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
//const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
//const Title = tw.h4`text-3xl font-bold text-gray-900`;
//const Description = tw.p`mt-2 text-sm leading-loose`;
const Title = tw.h4`text-3xl font-bold text-gray-700 text-center`;
const SubTitle = tw.h4`leading-relaxed font-bold text-lg`;
//const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg leading-relaxed text-secondary-100`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base text-gray-600`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const Subheading = tw(SubheadingBase)`text-center md:text-left font-bold`;

const SingleColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
//const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;

const SvgDotPattern1 = tw(
	SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-45 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24 mt-24`;
const SvgDotPattern2 = tw(
	SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
	SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
	SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
export default ({
}) => {
	return (
		<>
			<Container>
				<TwoColumn style={{ paddingBottom: '0' }}>
					<LeftColumn>
						<Subheading>Information for Students Applying to High School</Subheading>
						<Paragraph></Paragraph>
						<Heading>Eligibility</Heading>
						<Paragraph>Students studying in Grade 9, 10, 11 or 12</Paragraph>
						<Heading>Age</Heading>
						<Paragraph>14 to 18 years</Paragraph>
						<Heading>Intakes</Heading>
						<Paragraph>September and February of each year</Paragraph>
					</LeftColumn>
					<RightColumn>
						<Paragraph></Paragraph>
						<Heading>Fee Details</Heading>
						<Paragraph>Tuition Fee: $10,900.00 CAD per year [subject to revision and can be paid in installments]</Paragraph>
						<Paragraph>Registration Fee: $100.00 CAD Non-Refundable</Paragraph>
						<Heading>Documents Required</Heading>
						<Paragraph>Passport of both student and parent along with Academic documents of Student</Paragraph>
					</RightColumn>
				</TwoColumn>
			</Container>
		</>
	);
};
