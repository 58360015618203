import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import Home from "components/home/Main.js";
import AboutUs from "components/about/Main.js";
import Courses from "components/course/Courses.js";
import CoursesMain from "components/course/Main.js";
import Success from "components/misc/Success.js";
import NotFound from "components/misc/NotFound.js";
import EnrolledFreeSuccess from "components/misc/Success.js";
import Payment from "components/course/Payment.js";
import Tuition from "components/tuition/Main.js";
import CoursePayment from "components/course/CoursePayment.js";
import Grades from "components/grade/Main.js";

import Register from "components/common/Register";
import RegisterTuition from "components/tuition/Register";
import RegisterFree from "components/common/RegisterFree";
//import InternationalStudents from "components/internationals_new/Main.js";
import CanadaStudents from "components/students/Canada.js";
import MatureStudents from "components/students/MatureStudents.js";
import InternationalStudents from "components/students/International.js";
import FAQ from "components/faq/Main.js";
import Contact from "components/contact/Main";
import LangCourses from "components/lang_course/Main.js";
import Career from "components/career/Main.js";

import TuitionDetails from "components/tuition_details/Courses.js";

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

export default function App() {
  return (
    <Router forceRefresh>
      <Switch>
        <Route path="/"
          exact
          component={Home}>
        </Route>
        {/* <Route exact path="/home">
          <Home />
        </Route> */}
        
        <Route exact path="/online-schooling-in-ontario">
          <AboutUs />
        </Route>
        <Route exact path="/course/:course_code" component={Courses}></Route>
        <Route exact path="/course/:course_code" component={Courses}></Route>
        <Route
          exact
          path="/high-school-credit-courses-online"
          component={CoursesMain}
        ></Route>
        <Route
          exact
          path="/courses/:course_code"
          component={CoursesMain}
          onUpdate={() => window.scrollTo(0, 0)}
        ></Route>
        <Route exact path="/tuitions/:code" component={TuitionDetails}></Route>
        <Route exact path="/success">
          <Success />
        </Route>
        <Route exact path="/best-online-high-school-in-ontario">
          <FAQ />
        </Route>
        <Route exact path="/register">
          <Register />
        </Route>
        <Route exact path="/register_tuition">
          <RegisterTuition />
        </Route>
        <Route exact path="/joinus">
          <RegisterTuition />
        </Route>
        <Route exact path="/payment">
          <Payment />
        </Route>
        <Route exact path="/tuitions">
          <Tuition />
        </Route>
        <Route exact path="/course-payment">
          <CoursePayment />
        </Route>
        <Route exact path="/free-demo">
          <RegisterFree />
        </Route>
        <Route exact path="/grade/:grade" component={Grades}></Route>
        <Route exact path="/students/credit-courses-in-canada">
          <InternationalStudents />
        </Route>
        <Route exact path="/students/ontario-high-school-credit-courses">
          <CanadaStudents />
        </Route>
        <Route exact path="/students/ontario-secondary-school-diploma">
          <MatureStudents />
        </Route>
        <Route exact path="/best-high-school-ontario">
          <Contact />
        </Route>
        <Route exact path="/enrolled-successfully">
          <EnrolledFreeSuccess />
        </Route>
        <Route exact path="/lang_courses" component={LangCourses}></Route>
        <Route exact path="/career" component={Career}></Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}
