import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import { ReactComponent as PlayIcon1 } from "../../images/chevron-right.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import DesignIllustration from "../../images/design-illustration.svg";

//const Container = tw.div`relative`;
const Container = tw.div`relative m-8 mt-24`;
const Content = tw.div`rounded-2xl max-w-screen-xl mx-auto py-8 lg:pb-0 lg:pt-4 bg-primary-100`;
//const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
//const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
//const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;

const TwoColumn = tw.div`flex flex-wrap overflow-hidden pb-4`;
const LeftColumn = tw.div`my-5 px-5 w-full overflow-hidden md:w-1/2 lg:w-1/2 xl:w-1/2`;
const RightColumn = tw.div`my-5 px-5 w-full overflow-hidden md:w-1/2 lg:w-1/2 xl:w-1/2`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8 mb-8`;
const PrimaryButton = tw.button`font-bold text-xs md:text-base cursor-pointer px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const GetFreeDemoButton = styled.button`
  ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
  .playIcon {
    ${tw`stroke-1 w-12 h-12`}
  }
  .playText {
    ${tw`ml-2 font-medium`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;
const Heading = tw.h2`font-black font-bold text-2xl text-center lg:text-left lg:text-3xl leading-snug max-w-3xl mb-3`;
const Title = tw.h2`font-bold text-gray-700 text-left sm:text-center`;
const Description = tw.p`mt-2 text-center font-normal md:text-left text-sm md:text-base lg:text-lg 
leading-relaxed text-secondary-100`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;

export default ({
  description = "LBA grants Ontario Secondary School Diploma (OSSD) to all our international students that opens up new opportunities for their future studies. Our offered programs provide a new edge to their study profile. Ontario Secondary School Diploma(OSSD) program enables international students in our school to apply for globally recognized Colleges/ Universities.",
  primaryButtonText = "Get Started",
  primaryButtonUrl = "#",

  GetFreeDemoButtonText = "Get a Free Demo",
  watchVideoYoutubeUrl = "https://www.youtube.com/embed/_GuOjXYl5ew",

  imageSrc = DesignIllustration,
  imageCss = null,
  imageDecoratorBlob = false,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const openPdf = () =>
    // window.open(
    //   "https://lbnew.thinkstraight.org/upload/pdf/OSSD_DiplomaRequirements.pdf",
    //   "blank"
    // );
    window.open(
       global.config.main.ossd_requirment_pdf,
      "blank"
    );

  return (
    <>
      <Container>
        <Content>
          <TwoColumn
            style={{
              paddingTop: "4em",
              marginLeft: "0",
              marginRight: "0",
              padding: "2em",
              paddingBottom: "0",
            }}
          >
            <LeftColumn style={{ margin: "0", paddingLeft: "0" }}>
              <div
                className="flex flex-wrap overflow-hidden"
                style={{ paddingLeft: "0" }}
              >
                {/* <div className="w-full overflow-hidden">
                  <Heading>Ontario Secondary School Diploma</Heading>
                </div> */}
                <div className="w-full overflow-hidden">
                  <IllustrationContainer>
                    <img
                      style={{
                        borderRadius: "0",
                        marginTop: "50px",
                      }}
                      src={imageSrc}
                      alt="Online Private High School Ontario"
                    />
                  </IllustrationContainer>
                </div>
              </div>
            </LeftColumn>
            <RightColumn>
              {/*<Paragraph style={{"color":"#607B98","fontFamily":"LibreBaskerville-Regular","fontSize":"18px","letterSpacing":"-0.27px","lineHeight":"36px"}}>{description}</Paragraph>*/}
              <div className="w-full overflow-hidden">
                <Heading>Ontario Secondary School Diploma</Heading>
              </div>
              <Description>{description}</Description>
              <Actions>
                <PrimaryButton as="a" onClick={openPdf}>
                  View Requirements
                </PrimaryButton>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Actions>
            </RightColumn>
          </TwoColumn>
          <StyledModal
            closeTimeoutMS={300}
            className="mainHeroModal"
            isOpen={modalIsOpen}
            onRequestClose={toggleModal}
            shouldCloseOnOverlayClick={true}
          >
            <CloseModalButton onClick={toggleModal}>
              <CloseIcon tw="w-6 h-6" />
            </CloseModalButton>

            <div className="content">
              <ResponsiveVideoEmbed url={watchVideoYoutubeUrl} tw="w-full" />
            </div>
          </StyledModal>
        </Content>
      </Container>
    </>
  );
};
