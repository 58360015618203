import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";
import Header from "components/common/HeaderNew.js";
import BasicDetails from "components/common/Register_Payment.js";
import '../../config'


export default () => {
	return (
		<AnimationRevealPage>
			<Header />
			<BasicDetails />
			<Footer />
		</AnimationRevealPage>
	);
}
