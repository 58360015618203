import React, {Component} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/common/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

const styles = {width:"100%",height:"66rem"} // if you want to add some custom CSS
//const URL = "https://form.jotform.com/210500524982449"
const URL = "https://form.jotform.com/210521121653440"
	
const leftDescription = "should be same as your Government-issued identification"
const leftHeading = "Student Information*"
const rightDescription = "Building your student profile below. You will be send login instructions once you are enrolled in your course."
const rightSubDescription = "Fields marked with an asterisk (*) are mandatory."
const leftPartHeading = "Courses"
const rightPartHeading = "Price"
const submitButtonText = "Send"
const formAction = "#"
const formMethod = "get"
const textOnLeft = true
const leftPrimaryButtonText = "Payment"
const rightPrimaryButtonText = "Order Summary"
const primaryButtonUrl = ""
const leftSubHeading1 = "Integrated Arts, 9/10, Open"
const leftSubHeading2 = "Algebra, 9/10, Open (Fast Track Course)"
const rightSubHeading1 = "$479"
const rightSubHeading2 = "$657"

class Top extends Component {
	
	render() {
	return (
		<Container className="margin">
			<TwoColumn className="topPadding">
				<ImageColumn>
					<TextContent>
						<PrimaryButton as="a" style={{display:'inline-block',borderRadius:'10px',boxShadow:'0 10px 30px 0 rgba(0, 122, 255, 0.35)',borderStyle:'solid',borderWidth:'0',borderImageSource:'linear-gradient(to bottom, #5caaff, #007aff)',borderImageSlice:'1',backgroundImage:'linear-gradient(to bottom, #007aff, #007aff), linear-gradient(to bottom, #5caaff, #007aff)',backgroundOrigin:'border-box',marginBottom:'2em'}} href={primaryButtonUrl}>{rightPrimaryButtonText}</PrimaryButton>
						<TwoColumn className="topPadding" style={{paddingBottom:'30px'}}>
							<ImageColumn style={{width:'80%'}}>
								<Description className="contactUsHeadingBold noTopMargin">{leftPartHeading}</Description>
								<Description className="contactUsHeadingRegular">{leftSubHeading1}</Description>
								<Description className="contactUsHeadingRegular">{leftSubHeading2}</Description>
							</ImageColumn>
							<TextColumn>
								<Description className="contactUsHeadingBold noTopMargin">{rightPartHeading}</Description>
								<Description className="contactUsHeadingRegular">{rightSubHeading1}</Description>
								<Description className="contactUsHeadingRegular">{rightSubHeading2}</Description>
							</TextColumn>
						</TwoColumn>
						<hr />
						<TwoColumn style={{paddingTop:"30px"}}>
                            <ImageColumn style={{width:'80%'}}>
                                <Description className="contactUsHeadingBold noTopMargin">Total</Description>
                            </ImageColumn>
                            <TextColumn>
                                <Description className="contactUsHeadingBold noTopMargin">{this.props.data}</Description>
                            </TextColumn>
                        </TwoColumn>
					</TextContent>
				</ImageColumn>
				<TextColumn textOnLeft={textOnLeft}>
					<TextContent>
						<PrimaryButton as="a" style={{display:'inline-block',borderRadius:'10px',boxShadow:'0 10px 30px 0 rgba(0, 122, 255, 0.35)',borderStyle:'solid',borderWidth:'0',borderImageSource:'linear-gradient(to bottom, #5caaff, #007aff)',borderImageSlice:'1',backgroundImage:'linear-gradient(to bottom, #007aff, #007aff), linear-gradient(to bottom, #5caaff, #007aff)',backgroundOrigin:'border-box',marginBottom:"3em"}} href={primaryButtonUrl}>{leftPrimaryButtonText}</PrimaryButton>
						<iframe title="your title" style={styles} src={URL}></iframe>
					</TextContent>
				</TextColumn>
			</TwoColumn>
		</Container>
	)}
}
export default (Top);
