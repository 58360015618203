import React, { Component, useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/common/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "../../config.js";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { ReactComponent as SupportIcon } from "images/support-icon.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto pb-20 lg:pb-24 `;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-white border border-gray-100 shadow-md text-gray-600 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-2xl sm:text-3xl font-bold text-gray-700`}
  }
  input,
  textarea,
  select {
    ${tw`w-full bg-transparent text-gray-700 text-base font-medium tracking-wide border rounded-md  p-3 mt-1 border-gray-500  focus:border-primary-600 focus:border-2 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-400 font-light`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col md:flex-row md:justify-between`;
const Column = tw.div`flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-primary-500 text-white rounded font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const Features = tw.div`mx-auto md:mx-0 flex flex-col lg:flex-row max-w-xs lg:max-w-none`;
const FeatureHeadingContainer = tw.div`flex items-center justify-center`;
const FeatureHeading = tw.div`ml-3`;
const IconContainer = styled.div`
  ${tw`inline-block border border-primary-500 text-primary-500 text-center rounded p-2 flex-shrink-0`}
  ${props => [
    props.iconRoundedFull && tw`rounded-full`,
    props.iconFilled && tw`border-0 bg-primary-500 text-gray-100`
  ]}
  svg {
    ${tw`w-5 h-5`}
  }
`;


// const Container = tw.div`relative`;
// const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
// const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 md:h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const CustomForm = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
// const Input = tw.input`mt-6 first:mt-0 border py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;
const PrimaryButton = tw.button`cursor-default pointer-events-none font-bold px-8 lg:px-10 py-3 rounded bg-blue-100 text-secondary-500  focus:shadow-outline focus:outline-none transition duration-300`;
// const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

const styles = { width: "100%", height: "182rem" }; // if you want to add some custom CSS
const URL = global.config.main.JOT_REGISTRATION_FORM;

const leftDescription =
  "* should be same as your Government-issued identification.";
const leftHeading = "Student Information*";
const rightDescription =
  "You can submit your details in the form and leave your queries in the description text box. We will call or email you to resolve all your queries.";
const rightSubDescription = "Fields marked with an asterisk (*) are mandatory.";
const rightHeading = "Instructions";
const submitButtonText = "Send";
const formAction = "#";
const formMethod = "get";
const textOnLeft = true;
const primaryButtonText = "Create a Profile";
const primaryButtonUrl = "";

const AlertWrapper = tw.div`mb-5 w-auto text-center`;
const AlertText = styled.p((props) => [
  tw`p-1 py-2 rounded`,
  props.color === "red"
    ? tw`bg-red-100 text-red-700`
    : tw`bg-green-100 text-green-700`,
]);

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [alert, setAlert] = useState({
    show: false,
    color: "",
    msg: "",
  });

  const clearAlert = () =>
    setTimeout(() => setAlert({ show: false, color: "", msg: "" }), 10000);

  const [selectedGrade, setSelectedGrade] = useState(null);
  const [gradeArr, setGradeArr] = useState([]);
  const [gradeWiseCourseArr, setGradeWiseCourseArr] = useState([]);

  //get course code
  const getCourseCodeFunction = async () =>
    await axios.get(`${global.config.main.base_url}/getCourseCodes`);

  const {
    isLoading,
    data: CourseData,
    error,
  } = useQuery("getCourseCodes", getCourseCodeFunction);

  // for register user
  const registerUserFunction = async (body) =>
    await axios.post(`${global.config.main.base_url}/addStudentQuery`, body);

  const onRegisterUserSuccess = (data) => {
    // console.log("success", data);
    window.scrollTo({ top: 0 });
    setAlert({ show: true, color: "green", msg: data.data.msg });
    clearAlert();
  };
  const onRegisterUserError = (data) => {
    console.log("error", data.response);
    window.scrollTo({ top: 0 });
    setAlert({ show: true, color: "red", msg: data.response.data.msg });
    clearAlert();
  };

  const { isLoading: registerUserLoading, mutate: regiterUserMutate } =
    useMutation(registerUserFunction, {
      onSuccess: onRegisterUserSuccess,
      onError: onRegisterUserError,
    });

  // console.log("CourseData", CourseData);

  const InitialValues = {
    name: "",
    email: "",
    mobile: "",
    countryCode: "1",
    courseType: "",
    grade: "",
    //courseName: "",
    desc: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Please enter a valid email")
      .required("This field is required"),
    mobile: Yup.number("please enter a valid number").required(
      "This field is required"
    ),
    
    countryCode: Yup.string().required("This field is required"),
    courseType: Yup.string().required("This field is required"),
    //courseName: Yup.string().required("This field is required"),
    grade: Yup.string().required("This field is required"),
    //desc: Yup.string().required("This field is required"),
  });

  const SubmitHandler = (values) => {
    console.log("submit", values);

    const body = new FormData();
    body.append("name", values?.name);
    body.append("mobile", "+" + values?.countryCode + values?.mobile);
    body.append("countryCode", values?.countryCode);
    body.append("email", values?.email);
    body.append("description", values?.desc);
    body.append("grade", values?.grade);
    body.append("courseType", values?.courseType);
    //body.append("courseName", values?.courseName);

    regiterUserMutate(body);
  };

  useEffect(() => {
    const grades = CourseData?.data?.map((item) => item.grade);
    const uniqueGrades = Array.from(new Set(grades)).sort((a, b) => a - b);
    // console.log({ uniqueGrades });
    setGradeArr(uniqueGrades);
  }, [CourseData]);

  const onSelectChange = (values) => {
    // console.log("submit", values?.courseCode);
  };

  const onGradeChange = (e, formikProps) => {
    const val = e.target.value;
    formikProps.setFieldValue("grade", val);
    setSelectedGrade(val);
    //const newCourseArr = CourseData?.data?.filter((item) => item.grade === val);
    //setGradeWiseCourseArr(newCourseArr);
  };

  const onCourseTypeChange = (e, formikProps) => {
    const val = e.target.value;
    formikProps.setFieldValue("courseType", val);
    console.log(val);
    if (val === 'Non Credit Course')
    {
      setGradeArr([1,2,3,4,5,6,7,8,9,10,11,12]);
    }
    else
    {
        setGradeArr([9,10,11,12]);
    }
    // setSelectedGrade(val);
    // const newCourseArr = CourseData?.data?.filter((item) => item.grade === val);
    // setGradeWiseCourseArr(newCourseArr);
  };

  return (
    <Container className="margin">
      <TwoColumn className="topPadding">
        <ImageColumn>
          <TextContent>
            {/* <Description className="contactUsHeadingBold noTopMargin">
              {rightHeading}
            </Description> */}
            <Description className="contactUsHeadingRegular">
              {rightDescription}
            </Description>
            <Description className="contactUsHeadingRegular">
              {rightSubDescription}
            </Description>

            <Description className="contactUsHeadingRegular">OR</Description>
            <Description className="contactUsHeadingRegular">
              You can reach us directly at
            </Description>
            {/* <Features className="contactUsHeadingRegular">
            <FeatureHeadingContainer className="contactUsHeadingRegular">
              <IconContainer
                      iconFilled={true}
                iconRoundedFull={true}
                css={tw`bg-white text-teal-800`}
                    >
                      <SupportIcon />
              </IconContainer>
              <FeatureHeading className="contactUsHeadingRegular">416-887-0769</FeatureHeading>
              
            </FeatureHeadingContainer>
            </Features> */}
            {/* <Description className="contactUsHeadingRegular">
              +1-416-887-0769
            </Description> */}
            <Description className="contactUsHeadingRegular">
              Email: info@learningbeamacademy.ca
            </Description>
            <Description className="contactUsHeadingRegular">
              Address: Unit 209, 19 Woodbines Down Blvd Etobicoke, Toronto Ontario, M9W 6N5
            </Description>
          </TextContent>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {/*<Description className="contactUsHeadingBold">{leftHeading}</Description>*/}
            {/* <iframe title="your title" style={styles} src={URL}></iframe> */}

            <FormComp
              InitialValues={InitialValues}
              validationSchema={validationSchema}
              SubmitHandler={SubmitHandler}
              alert={alert}
              CourseData={CourseData}
              onSelectChange={onSelectChange}
              onGradeChange={onGradeChange}
              onCourseTypeChange={onCourseTypeChange}
              selectedGrade={selectedGrade}
              gradeArr={gradeArr}
              //gradeWiseCourseArr={gradeWiseCourseArr}
            />
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

const Alert = ({ msg, color }) => {
  return (
    <AlertWrapper>
      <AlertText role="alert" color={color}>
        <span tw="block sm:inline text-xs">{msg}</span>
      </AlertText>
    </AlertWrapper>
  );
};

const FormComp = ({
  alert,
  InitialValues,
  SubmitHandler,
  validationSchema,
  CourseData,
  onSelectChange,
  onGradeChange,
  onCourseTypeChange,
  selectedGrade,
  gradeWiseCourseArr,
  gradeArr,
}) => {
  return (
    <Formik
      initialValues={InitialValues}
      onSubmit={SubmitHandler}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <Container>
          <Content>
            <FormContainer>
              {alert.show && (
                <Alert msg={alert.msg} color={alert.color || "red"} />
              )}
              <div>
                <h2>Contact Us</h2>
                <Form>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="name-input">Name*</Label>
                      <Field
                        id="name-input"
                        type="text"
                        name="name"
                        placeholder="E.g. John Doe"
                        required
                      />
                      <ErrorMessage name="name" component={ErrorMessageComp} />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="email-input">Email Address*</Label>
                      <Field
                        id="email-input"
                        type="email"
                        name="email"
                        required
                        placeholder="E.g. john@mail.com"
                      />
                      <ErrorMessage name="email" component={ErrorMessageComp} />
                    </InputContainer>

                    <InputContainer>
                      <Label htmlFor="mobile">Mobile No.*</Label>
                      <div tw="flex w-full h-full ">
                        <Field name="countryCode">
                          {(props) => (
                            <Select
                              {...props.field}
                              className="w-full h-full pl-2 py-1 bg-transparent text-sm p-0  border-none remove-border-radius "
                              autoComplete="off"
                              style={{
                                width: "100px",
                                marginTop: 4,
                                paddingLeft: "5px",
                                borderRadius: "5px 0px 0px 5px",
                              }}
                              required
                            >
                              <MenuItem value="213">+213</MenuItem>
                              <MenuItem value="376">+376</MenuItem>
                              <MenuItem value="244">+244</MenuItem>
                              <MenuItem value="1264">+1264</MenuItem>
                              <MenuItem value="1268">+1268</MenuItem>
                              <MenuItem value="54">+54</MenuItem>
                              <MenuItem value="374">+374</MenuItem>
                              <MenuItem value="297">+297</MenuItem>
                              <MenuItem value="61">+61</MenuItem>
                              <MenuItem value="43">+43</MenuItem>
                              <MenuItem value="994">+994</MenuItem>
                              <MenuItem value="1242">+1242</MenuItem>
                              <MenuItem value="973">+973</MenuItem>
                              <MenuItem value="880">+880</MenuItem>
                              <MenuItem value="1246">+1246</MenuItem>
                              <MenuItem value="375">+375</MenuItem>
                              <MenuItem value="32">+32</MenuItem>
                              <MenuItem value="501">+501</MenuItem>
                              <MenuItem value="229">+229</MenuItem>
                              <MenuItem value="1441">+1441</MenuItem>
                              <MenuItem value="975">+975</MenuItem>
                              <MenuItem value="591">+591</MenuItem>
                              <MenuItem value="387">+387</MenuItem>
                              <MenuItem value="267">+267</MenuItem>
                              <MenuItem value="55">+55</MenuItem>
                              <MenuItem value="673">+673</MenuItem>
                              <MenuItem value="359">+359</MenuItem>
                              <MenuItem value="226">+226</MenuItem>
                              <MenuItem value="257">+257</MenuItem>
                              <MenuItem value="855">+855</MenuItem>
                              <MenuItem value="237">+237</MenuItem>
                              <MenuItem value="1">+1</MenuItem>
                              <MenuItem value="238">+238</MenuItem>
                              <MenuItem value="1345">+1345</MenuItem>
                              <MenuItem value="236">+236</MenuItem>
                              <MenuItem value="56">+56</MenuItem>
                              <MenuItem value="86">+86</MenuItem>
                              <MenuItem value="57">+57</MenuItem>
                              <MenuItem value="269">+269</MenuItem>
                              <MenuItem value="242">+242</MenuItem>
                              <MenuItem value="682">+682</MenuItem>
                              <MenuItem value="506">+506</MenuItem>
                              <MenuItem value="385">+385</MenuItem>
                              <MenuItem value="53">+53</MenuItem>
                              <MenuItem value="90392">+90392</MenuItem>
                              <MenuItem value="357">+357</MenuItem>
                              <MenuItem value="42">+42</MenuItem>
                              <MenuItem value="45">+45</MenuItem>
                              <MenuItem value="253">+253</MenuItem>
                              <MenuItem value="1809">+1809</MenuItem>
                              <MenuItem value="1809">+1809</MenuItem>
                              <MenuItem value="593">+593</MenuItem>
                              <MenuItem value="20">+20</MenuItem>
                              <MenuItem value="503">+503</MenuItem>
                              <MenuItem value="240">+240</MenuItem>
                              <MenuItem value="291">+291</MenuItem>
                              <MenuItem value="372">+372</MenuItem>
                              <MenuItem value="251">+251</MenuItem>
                              <MenuItem value="500">+500</MenuItem>
                              <MenuItem value="298">+298</MenuItem>
                              <MenuItem value="679">+679</MenuItem>
                              <MenuItem value="358">+358</MenuItem>
                              <MenuItem value="33">+33</MenuItem>
                              <MenuItem value="594">+594</MenuItem>
                              <MenuItem value="689">+689</MenuItem>
                              <MenuItem value="241">+241</MenuItem>
                              <MenuItem value="220">+220</MenuItem>
                              <MenuItem value="7880">+7880</MenuItem>
                              <MenuItem value="49">+49</MenuItem>
                              <MenuItem value="233">+233</MenuItem>
                              <MenuItem value="350">+350</MenuItem>
                              <MenuItem value="30">+30</MenuItem>
                              <MenuItem value="299">+299</MenuItem>
                              <MenuItem value="1473">+1473</MenuItem>
                              <MenuItem value="590">+590</MenuItem>
                              <MenuItem value="671">+671</MenuItem>
                              <MenuItem value="502">+502</MenuItem>
                              <MenuItem value="224">+224</MenuItem>
                              <MenuItem value="245">+245</MenuItem>
                              <MenuItem value="592">+592</MenuItem>
                              <MenuItem value="509">+509</MenuItem>
                              <MenuItem value="504">+504</MenuItem>
                              <MenuItem value="852">+852</MenuItem>
                              <MenuItem value="36">+36</MenuItem>
                              <MenuItem value="354">+354</MenuItem>
                              <MenuItem value="91">+91</MenuItem>
                              <MenuItem value="62">+62</MenuItem>
                              <MenuItem value="98">+98</MenuItem>
                              <MenuItem value="964">+964</MenuItem>
                              <MenuItem value="353">+353</MenuItem>
                              <MenuItem value="972">+972</MenuItem>
                              <MenuItem value="39">+39</MenuItem>
                              <MenuItem value="1876">+1876</MenuItem>
                              <MenuItem value="81">+81</MenuItem>
                              <MenuItem value="962">+962</MenuItem>
                              <MenuItem value="7">+7</MenuItem>
                              <MenuItem value="254">+254</MenuItem>
                              <MenuItem value="686">+686</MenuItem>
                              <MenuItem value="850">+850</MenuItem>
                              <MenuItem value="82">+82</MenuItem>
                              <MenuItem value="965">+965</MenuItem>
                              <MenuItem value="996">+996</MenuItem>
                              <MenuItem value="856">+856</MenuItem>
                              <MenuItem value="371">+371</MenuItem>
                              <MenuItem value="961">+961</MenuItem>
                              <MenuItem value="266">+266</MenuItem>
                              <MenuItem value="231">+231</MenuItem>
                              <MenuItem value="218">+218</MenuItem>
                              <MenuItem value="417">+417</MenuItem>
                              <MenuItem value="370">+370</MenuItem>
                              <MenuItem value="352">+352</MenuItem>
                              <MenuItem value="853">+853</MenuItem>
                              <MenuItem value="389">+389</MenuItem>
                              <MenuItem value="261">+261</MenuItem>
                              <MenuItem value="265">+265</MenuItem>
                              <MenuItem value="60">+60</MenuItem>
                              <MenuItem value="960">+960</MenuItem>
                              <MenuItem value="223">+223</MenuItem>
                              <MenuItem value="356">+356</MenuItem>
                              <MenuItem value="692">+692</MenuItem>
                              <MenuItem value="596">+596</MenuItem>
                              <MenuItem value="222">+222</MenuItem>
                              <MenuItem value="269">+269</MenuItem>
                              <MenuItem value="52">+52</MenuItem>
                              <MenuItem value="691">+691</MenuItem>
                              <MenuItem value="373">+373</MenuItem>
                              <MenuItem value="377">+377</MenuItem>
                              <MenuItem value="976">+976</MenuItem>
                              <MenuItem value="1664">+1664</MenuItem>
                              <MenuItem value="212">+212</MenuItem>
                              <MenuItem value="258">+258</MenuItem>
                              <MenuItem value="95">+95</MenuItem>
                              <MenuItem value="264">+264</MenuItem>
                              <MenuItem value="674">+674</MenuItem>
                              <MenuItem value="977">+977</MenuItem>
                              <MenuItem value="31">+31</MenuItem>
                              <MenuItem value="687">+687</MenuItem>
                              <MenuItem value="64">+64</MenuItem>
                              <MenuItem value="505">+505</MenuItem>
                              <MenuItem value="227">+227</MenuItem>
                              <MenuItem value="234">+234</MenuItem>
                              <MenuItem value="683">+683</MenuItem>
                              <MenuItem value="672">+672</MenuItem>
                              <MenuItem value="670">+670</MenuItem>
                              <MenuItem value="47">+47</MenuItem>
                              <MenuItem value="968">+968</MenuItem>
                              <MenuItem value="680">+680</MenuItem>
                              <MenuItem value="507">+507</MenuItem>
                              <MenuItem value="675">+675</MenuItem>
                              <MenuItem value="595">+595</MenuItem>
                              <MenuItem value="51">+51</MenuItem>
                              <MenuItem value="63">+63</MenuItem>
                              <MenuItem value="48">+48</MenuItem>
                              <MenuItem value="351">+351</MenuItem>
                              <MenuItem value="1787">+1787</MenuItem>
                              <MenuItem value="974">+974</MenuItem>
                              <MenuItem value="262">+262</MenuItem>
                              <MenuItem value="40">+40</MenuItem>
                              <MenuItem value="7">+7</MenuItem>
                              <MenuItem value="250">+250</MenuItem>
                              <MenuItem value="378">+378</MenuItem>
                              <MenuItem value="239">+239</MenuItem>
                              <MenuItem value="966">+966</MenuItem>
                              <MenuItem value="221">+221</MenuItem>
                              <MenuItem value="381">+381</MenuItem>
                              <MenuItem value="248">+248</MenuItem>
                              <MenuItem value="232">+232</MenuItem>
                              <MenuItem value="65">+65</MenuItem>
                              <MenuItem value="421">+421</MenuItem>
                              <MenuItem value="386">+386</MenuItem>
                              <MenuItem value="677">+677</MenuItem>
                              <MenuItem value="252">+252</MenuItem>
                              <MenuItem value="27">+27</MenuItem>
                              <MenuItem value="34">+34</MenuItem>
                              <MenuItem value="94">+94</MenuItem>
                              <MenuItem value="290">+290</MenuItem>
                              <MenuItem value="1869">+1869</MenuItem>
                              <MenuItem value="1758">+1758</MenuItem>
                              <MenuItem value="249">+249</MenuItem>
                              <MenuItem value="597">+597</MenuItem>
                              <MenuItem value="268">+268</MenuItem>
                              <MenuItem value="46">+46</MenuItem>
                              <MenuItem value="41">+41</MenuItem>
                              <MenuItem value="963">+963</MenuItem>
                              <MenuItem value="886">+886</MenuItem>
                              <MenuItem value="7">+7</MenuItem>
                              <MenuItem value="66">+66</MenuItem>
                              <MenuItem value="228">+228</MenuItem>
                              <MenuItem value="676">+676</MenuItem>
                              <MenuItem value="1868">+1868</MenuItem>
                              <MenuItem value="216">+216</MenuItem>
                              <MenuItem value="90">+90</MenuItem>
                              <MenuItem value="7">+7</MenuItem>
                              <MenuItem value="993">+993</MenuItem>
                              <MenuItem value="1649">+1649</MenuItem>
                              <MenuItem value="688">+688</MenuItem>
                              <MenuItem value="256">+256</MenuItem>
                              <MenuItem value="44">+44</MenuItem>
                              <MenuItem value="380">+380</MenuItem>
                              <MenuItem value="971">+971</MenuItem>
                              <MenuItem value="598">+598</MenuItem>
                              <MenuItem value="1">+1</MenuItem>
                              <MenuItem value="7">+7</MenuItem>
                              <MenuItem value="678">+678</MenuItem>
                              <MenuItem value="379">+379</MenuItem>
                              <MenuItem value="58">+58</MenuItem>
                              <MenuItem value="84">+84</MenuItem>
                              <MenuItem value="84">+1284</MenuItem>
                              <MenuItem value="84">+1340</MenuItem>
                              <MenuItem value="681">+681</MenuItem>
                              <MenuItem value="969">+969</MenuItem>
                              <MenuItem value="967">+967</MenuItem>
                              <MenuItem value="260">+260</MenuItem>
                              <MenuItem value="263">+263</MenuItem>
                            </Select>
                          )}
                        </Field>
                        <Field
                          id="mobile"
                          type="number"
                          name="mobile"
                          placeholder="9999 999 999"
                          tw="pl-3"
                          style={{ borderRadius: "0px 5px 5px 0px" }}
                        />
                      </div>
                      <ErrorMessage
                        name="mobile"
                        component={ErrorMessageComp}
                      />
                    </InputContainer>

                    <InputContainer>
                      <Label htmlFor="courseType">Course Type*</Label>
                      <Field
                        id="courseType"
                        type="text"
                        name="courseType"
                      >
                        {(props) => (
                          <Select
                            {...props.field}
                            autoComplete="off"
                            onChange={(e) => onCourseTypeChange(e, formikProps)}
                            tw="w-full bg-transparent text-gray-700 text-base font-medium tracking-wide border-0 border-gray-500  hocus:border-primary-600 focus:outline-none transition duration-200 text-left mt-1"
                          >
                            <MenuItem value="" disabled>
                              select-course-type
                            </MenuItem>
                            <MenuItem value="Credit Course">
                              Credit Course
                            </MenuItem>
                            <MenuItem value="Non Credit Course">
                              Non Credit Course
                            </MenuItem>
                          </Select>
                        )}
                      </Field>
                      <ErrorMessage
                        name="courseType"
                        component={ErrorMessageComp}
                      />
                    </InputContainer>


                    <InputContainer>
                      <Label htmlFor="grade">Select Grade*</Label>
                      <Field id="grade" type="text" name="grade">
                        {(props) => (
                          <Select
                            {...props.field}
                            autoComplete="off"
                            onChange={(e) => onGradeChange(e, formikProps)}
                            tw="w-full bg-transparent text-gray-700 text-base font-medium tracking-wide border-0 border-gray-500  hocus:border-primary-600 focus:outline-none transition duration-200 text-left mt-1"
                          >
                            <MenuItem value="" disabled>
                              select-grade
                            </MenuItem>
                            {gradeArr?.map((grade) => (
                              <MenuItem value={grade}>{grade}</MenuItem>
                            ))}
                          </Select>
                        )}
                      </Field>
                      <ErrorMessage name="grade" component={ErrorMessageComp} />
                    </InputContainer>

                    {/* <InputContainer>
                      <Label htmlFor="courseName">Course Name*</Label>
                      <Field
                        id="courseName"
                        type="text"
                        name="courseName"
                        placeholder="MM112"
                      >
                        {(props) => (
                          <Select
                            {...props.field}
                            autoComplete="off"
                            disabled={selectedGrade ? false : true}
                            tw="p-1 w-full bg-transparent text-gray-700 text-base font-medium tracking-wide border-0 border-gray-500  hocus:border-primary-600 focus:outline-none transition duration-200 text-left mt-1"
                          >
                            <MenuItem value="" disabled>
                              select-course-code
                            </MenuItem>
                            {gradeWiseCourseArr?.map((course) => (
                              <MenuItem value={course.courseName}>
                                {course.courseName}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </Field>
                      <ErrorMessage
                        name="courseName"
                        component={ErrorMessageComp}
                      />
                    </InputContainer> */}

                    <InputContainer tw="flex-1">
                      <Label htmlFor="desc">Description</Label>
                      <Field
                        as="textarea"
                        id="desc"
                        name="desc"
                        placeholder="E.g. Please describe about your self"
                      />
                      {/* <ErrorMessage name="desc" component={ErrorMessageComp} /> */}
                    </InputContainer>
                  </Column>

                  <SubmitButton type="submit">Submit</SubmitButton>
                </Form>
              </div>
            </FormContainer>
          </Content>
        </Container>
      )}
    </Formik>
  );
};

const ErrorMessageComp = ({ children }) => (
  <p tw="text-red-500 mt-1 text-sm text-left">{children}</p>
);

export default Contact;
