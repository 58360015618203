import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import CoursesOffered from "components/course/Offer.js";
import Header from "../common/HeaderNew.js";
import Unique from "components/common/Unique.js";
import Stats from "components/common/Stats.js";
import PeopleTestimonial from "components/common/Testimonials.js";
import BestPart from "components/common/BestPartHome.js";
import Stats_New from "components/common/Stats_New.js";
import Stats_New_Orig from "components/common/ThreeColCenteredStatsPrimaryBackground.js";
import Faq from "components/common/Faq.js";
import Hero from "components/home/Top.js";
import School from "components/home/school.js";
import Footer from "components/common/Footer.js";
import MetaTags from 'react-meta-tags';
import NeedHelp from "../common/NeedHelp.js";
//import chefIconImageSrc from "images/chef-icon.svg";
//import celebrationIconImageSrc from "images/celebration-icon.svg";
//import shopIconImageSrc from "images/shop-icon.svg";

import bookOpen from "../../images/book-open.png";
import bitMap from "../../images/Bitmap.png";
import barChart from "../../images/bar-chart.png";
import checkCircle from "../../images/check-circle.png";

//import homeImage from "../../images/home_image.png";
import homeImage from "../../images/home_3.png";
import bestPartImage from "../../images/best_part.png";
const Container = tw.div`relative mt-20`;

export default () => {

  // useEffect(() => {
  //   document.title = 'Best Online Schooling in Ontario - Get a free Demo';
  //   document.description = 'Best Online Schooling in Ontario, Online Schooling in Ontario, Online Schooling, Ontario Best Online virtual high school, Best Ontario Online School, Credit Courses, Grade 9, Grade 10, Grade 11, Grade 12, Free online classes';
  // }, []);
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>

      <MetaTags>
        <title>Best Online Schooling in Ontario - Get a free Demo</title>
        <meta name="description" content="Learning Beam Academy is the best online schooling in Ontario. We provide more than 100 school courses- Grade 9, Grade 10, Grade 11, Grade 12. Get a free demo. " />
        <meta name="keywords" content="Best online schooling Ontario, Online schooling Ontario, Online schooling in Ontario, Ontario Online School" />
        <meta property="og:title" content="Best Online Schooling in Ontario - Get a free Demo" />
        <meta property="og:image" content="/static/media/logo.358fb4cd.svg" alt="best online schooling in Ontario" />
      </MetaTags>
      <Header />
      <Container>
        <Faq heading="Frequently Asked Questions" />
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
}
