import React, { Component } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";

import MetaTags from "react-meta-tags";

import Header from "components/common/HeaderNew.js";
import Stats from "components/common/Stats_New.js";
import Unique from "components/common/Unique.js";
import BestPart from "components/common/BestPart.js";
import Desc from "components/about/Desc.js";
import Faq from "components/about/Faq.js";
import FaqNew from "components/about/Faqback.js";
import Top from "components/about/Top.js";
import WhyLearning from "components/common/WhyLearning.js";

import bookOpen from "../../images/book-open.png";
import bitMap from "../../images/Bitmap.png";
import barChart from "../../images/bar-chart.png";
import checkCircle from "../../images/check-circle.png";
import aboutImage from "../../images/about_1.png";
import mainImage from "../../images/home.png";

const imageCss = tw`rounded-4xl`;
class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <AnimationRevealPage>
        <MetaTags>
          <title>
            High School Credit Courses | Online Schooling in Ontario
          </title>
          <meta
            name="description"
            content="The Best Online high School credit courses in Ontario Canada to obtain your High School Diploma OSSD. Single Courses, Full program. tutorships, flexible schedule. Contact us NOW."
          />
          <meta
            name="keywords"
            content=" High School Credit Courses, High School Diploma, Best Online high School credit courses, Online Schooling in Toronto"
          />
          <meta
            property="og:title"
            content="High School Credit Courses | Online Schooling in Ontario"
          />
          <meta
            property="og:image"
            content="/static/media/logo.358fb4cd.svg"
            alt="best online schooling Ontario"
          />
        </MetaTags>
        {/* <Header /> */}
        <Top
          heading={<>Best Online High School Ontario</>}
          description="Our Ontario Secondary School Diploma (OSSD) and Credit Courses are accepted by all top colleges/ universities globally."
          imageSrc={mainImage}
          imageCss={imageCss}
          imageDecoratorBlob={true}
        />
        {/* <hr style={{ width: "80%", margin: "auto", justifyContent: "center" }} /> */}
        <hr style={{ width: "84%", marginLeft: "2.5em" }} />
        <Desc />
        <WhyLearning />
        <Stats />
        <Unique
          heading={<>What makes us unique?</>}
          cards={[
            {
              imageSrc: bookOpen,
              title: "100% Satisfaction",
              url: "https://www.google.com/search?q=learning+beam+academy&sxsrf=APq-WBvTnXyFnp3T-liggr2y_VqeTelHHA%3A1645866055626&source=hp&ei=R-wZYtHUI6GmwAO8i6igCg&iflsig=AHkkrS4AAAAAYhn6VzJle9Gdheym8o5fu3ENS9uPZGP-&ved=0ahUKEwiRlN3sgJ32AhUhE3AKHbwFCqQQ4dUDCAc&uact=5&oq=learning+beam+academy&gs_lcp=Cgdnd3Mtd2l6EAMyBAgjECc6CwgAEIAEELEDEIMBOgUIABCABDoLCC4QgAQQsQMQgwE6CAgAEIAEELEDOhEILhCABBCxAxCDARDHARCvAToFCC4QgAQ6CwguEIAEEMcBEK8BOgYIABAWEB46CAgAEBYQChAeUABY6SVgoyloAHAAeAGAAb8DiAGbKpIBCjAuMy4xMC42LjGYAQCgAQE&sclient=gws-wiz",
            },
            {
              imageSrc: bitMap,
              title: "Free Demo",
              url: "https://learningbeamacademy.ca/free-demo",
            },
            {
              imageSrc: barChart,
              title: "100% Success Result",
            },
            {
              imageSrc: checkCircle,
              title: "Live Assessment",
            },
          ]}
          imageContainerCss={tw`p-2!`}
          imageCss={tw`w-20! h-20!`}
        />
        <br />
        {/* <Faq heading="Frequently Asked Questions" /> */}
        <BestPart
          imageSrc={aboutImage}
          imageCss={imageCss}
          imageDecoratorBlob={true}
          primaryButtonText="Join Us"
          watchVideoButtonText="Get a Free Demo"
        />
        {/* <FaqNew /> */}
        <Footer />
      </AnimationRevealPage>
    );
  }
}
export default About;
