import React, { Component } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import "../../config.js";

import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import logo from "../../images/logo.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto px-5 md:px-10
`;
const MenuToggleIcon = motion.custom(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-4 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 focus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 sm:text-xs md:text-xl lg:text-2xl ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion.custom(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const MobileNavLinks1 = motion.custom(styled.div`
  ${tw`z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const NavToggle1 = tw.button`
  z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;

export const NavDropLinks = motion.custom(styled.div`
  ${tw`z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

class Headers extends Component {
  //const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  //const { myId } = useState("topnav");
  constructor() {
    super();
    this.state = {
      showNavLinks: false,
      showDropDown: false,
      collapseBreakpointClass: "xl",
      isDisplay: "none",
      isDisplayDropDown: "none",
      defaultLinks: [
        <NavLinks key={1}>
          <NavLink to="/high-school-credit-courses-online">Courses</NavLink>
          {/* <a href="#home" class="active">Courses</a> */}
          <div className="dropdown">
            <button className="dropbtn">
              Students
              <i className="fa fa-caret-down"></i>
            </button>
            <div className="dropdown-content">
              <NavLink to="/students/ontario-high-school-credit-courses">
                Ontario Students
              </NavLink>
              <NavLink to="/students/ontario-secondary-school-diploma">
                Mature Students
              </NavLink>
              <NavLink to="/students/credit-courses-in-canada">
                International Students
              </NavLink>
              <a
                href="https://lbnew.thinkstraight.org/upload/pdf/OSSD_DiplomaRequirements.pdf"
                target="_blank" rel="noopener noreferrer"
              >
                Ontario Ministry Requirement
              </a>
            </div>
          </div>

          <NavLink to="/blogs" target="_blank" rel="noopener noreferrer">
            News
          </NavLink>
          <NavLink to="/online-schooling-in-ontario">About Us</NavLink>
          <NavLink to="/best-online-high-school-in-Ontario">FAQ</NavLink>
        </NavLinks>,

        <NavLinks key={2}>
          {/* <Link to="/register">
            <PrimaryLink>Register</PrimaryLink>
          </Link> */}
           <Link to="/joinus">
            <PrimaryLink>Join Us</PrimaryLink>
          </Link>
        </NavLinks>,
      ],
    };
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  // defaultLogoLink() {
  //   return
  //   <LogoLink href="/">
  //     <img src={logo} alt="logo" style={{ width: '62px' }} />
  //     Learning Beam Academy
  //   </LogoLink>;
  // }

  toggleNavbar() {
    //this.state.collapseBreakpointClass = "sm";

    if (this.state.isDisplay == "none") {
      this.setState({
        isDisplay: "block",
        showNavLinks: true,
      });
    } else {
      this.setState({
        isDisplay: "none",
        showNavLinks: false,
      });
    }
  }

  toggleDropdown() {
    if (this.state.showDropDown == false) {
      this.setState({
        isDisplayDropDown: "block",
        showDropDown: true,
      });
    } else {
      this.setState({
        isDisplayDropDown: "none",
        showDropDown: false,
      });
    }
    //alert(this.state.showDropDown)
  }

  //logoLink = logoLink || defaultLogoLink;
  //links = links || defaultLinks;
  render() {
    const collapseBreakPointCssMap = {
      sm: {
        mobileNavLinks: tw`sm:hidden`,
        desktopNavLinks: tw`sm:flex`,
        mobileNavLinksContainer: tw`sm:hidden`,
      },
      md: {
        mobileNavLinks: tw`md:hidden`,
        desktopNavLinks: tw`md:flex`,
        mobileNavLinksContainer: tw`md:hidden`,
      },
      lg: {
        mobileNavLinks: tw`lg:hidden`,
        desktopNavLinks: tw`lg:flex`,
        mobileNavLinksContainer: tw`lg:hidden`,
      },
      xl: {
        mobileNavLinks: tw`lg:hidden`,
        desktopNavLinks: tw`lg:flex`,
        mobileNavLinksContainer: tw`lg:hidden`,
      },
    };
    const collapseBreakpointCss =
      collapseBreakPointCssMap[this.state.collapseBreakpointClass];
    return (
      <Header>
        <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
          {/* {this.state.defaultLinks} */}
          <LogoLink href="/">
            {/* <img src={logo} alt="logo" style={{ width: "220px" }} /> */}
            {/* Learning Beam Academy */}
            <img src={logo} alt="logo" style={{ width: "320px" }} />
            {/* Learning Beam Academy */}
          </LogoLink>
          <div className="topnav" id="myTopnav">
            <Link to="/high-school-credit-courses-online">Courses</Link>
            {/* <a href="#home" class="active">Courses</a> */}
            <div className="dropdown">
              <button className="dropbtn">
                Students
                <i className="fa fa-caret-down"></i>
              </button>
              <div className="dropdown-content">
                <Link to="/students/ontario-high-school-credit-courses">
                  Ontario Students
                </Link>
                <Link to="/students/ontario-secondary-school-diploma">
                  Mature Students
                </Link>
                <Link to="/students/credit-courses-in-canada">
                  International Students
                </Link>
                <a
                  href={global.config.main.ossd_requirment_pdf }
                  target="_blank" rel="noopener noreferrer"
                >
                  Ontario Ministry Requirement
                </a>
              </div>
            </div>
            {/* <Link to="/international-students">International Students</Link> */}
            {/* <Link to="/tuitions">Tuitions</Link> */}
            <Link to="/blogs" target="_blank" rel="noopener noreferrer">
              News
            </Link>
            <Link to="/online-schooling-in-ontario">About Us</Link>
            <Link to="/best-online-high-school-in-ontario">FAQ</Link>
          </div>
          <Link to="/joinus">
            <PrimaryLink>Join Us</PrimaryLink>
          </Link>
        </DesktopNavLinks>

        <MobileNavLinksContainer
          css={collapseBreakpointCss.mobileNavLinksContainer}
        >
          <LogoLink href="/">
            <img src={logo} alt="logo" style={{ width: '200px' }}/>
            {/* Learning Beam Academy */}
          </LogoLink>
          <MobileNavLinks
            style={{ display: this.state.isDisplay }}
            css={collapseBreakpointCss.mobileNavLinks}
          >
            <div className="topnav responsive" id="myTopnav">
              <Link to="/high-school-credit-courses-online">Courses</Link>
              {/* <a href="#home" class="active">Courses</a> */}
              <div className="dropdown">
                <button className="dropbtn">
                  Students
                  <i className="fa fa-caret-down"></i>
                </button>
                <div className="dropdown-content">
                  <Link to="/students/ontario-high-school-credit-courses">
                    Ontario Students
                  </Link>
                  <Link to="/students/ontario-secondary-school-diploma">
                    Mature Students
                  </Link>
                  <Link to="/students/credit-courses-in-canada">
                    International Students
                  </Link>
                  <a
                    href="https://lbnew.thinkstraight.org/upload/pdf/OSSD_DiplomaRequirements.pdf"
                    target="_blank" rel="noopener noreferrer"
                  >
                    Ontario Ministry Requirement
                  </a>
                </div>
              </div>
              {/* <Link to="/international-students">International Students</Link> */}
              {/* <Link to="/tuitions">Tuitions</Link> */}
              <Link to="/blogs" target="_blank" rel="noopener noreferrer">
                News
              </Link>
              <Link to="/online-schooling-in-ontario">About Us</Link>
              <Link to="/best-online-high-school-in-Ontario">FAQ</Link>
            </div>
          </MobileNavLinks>

          <NavToggle
            onClick={this.toggleNavbar}
            className={this.state.showNavLinks ? "open" : "closed"}
          >
            {this.state.showNavLinks ? (
              <CloseIcon tw="w-6 h-6" />
            ) : (
              <MenuIcon tw="w-6 h-6" />
            )}
          </NavToggle>
        </MobileNavLinksContainer>
      </Header>
    );
  }
}

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

export default Headers;
