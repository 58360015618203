import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingTitle } from "../common/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";
import schoolImage from "../../images/courseMain.png";
//import schoolImage1 from "../../images/school1.jpg";
//import schoolImage1 from "../../images/courseMain.png";
import schoolImage1 from "../../images/home.png";

//import schoolImage2 from "../../images/school2.jpg";
import schoolImage2 from "../../images/lang_course_main.png";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-20`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-5xl`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm lg:max-w-xs`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded`,
]);
const Category = tw.div`mt-4 text-secondary-100 font-bold text-sm`;
const CardTitle = tw.h4`mt-2 leading-relaxed font-bold text-lg`;
const Title = tw.h3`text-3xl font-bold text-gray-700 text-center`;
const Link = tw.a`inline-block mt-2 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default () => {
  const blogPosts = [
    {
      imageSrc: schoolImage,
      category: "Ontario Students",
      title: "What Our Ontario & Domestic Students Will Get",
      url: "/students/Ontario-High-School-Credit-Courses",
    },
    {
      imageSrc: schoolImage1,
      category: "International Students",
      title: "What Our International Students Will Get",
      url: "/students/credit-courses-in-canada",
    },
    {
      imageSrc: schoolImage2,
      category: "Mature Students",
      title: "Get the Ontario Secondary School Diploma",
      url: "/students/ontario-secondary-school-diploma",
    },
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <Title>Ministry Inspected Ontario Online High School</Title>
          <HeadingDescription>
            We are registered and inspected by the Ontario Ministry of Education
            (BSID #889197). Students in Canada and abroad can procure their
            Ontario Secondary School Diploma (OSSD), Credit Courses totally
            online through our online programs.
          </HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
                <Category>{post.category}</Category>
                <CardTitle>{post.title}</CardTitle>
                <Link target="_blank" href={post.url} rel="noopener noreferrer">
                  Read More
                </Link>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
