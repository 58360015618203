import React from "react";
import { useEffect } from "react";

import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import PeopleTestimonial from "components/common/Testimonials.js";
import CoursesOffered from "components/course/Offer.js";
import BestPart from "components/common/BestPart.js";
import AllCourses from "components/course/AllCourses.js";
import Top from "components/course/MainTop.js";
import Footer from "components/common/Footer.js";
import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";
import HowDoesItWork from "components/common/HowDoesItWork.js";
import MetaTags from "react-meta-tags";

import courseMainImage from "../../images/courseMain.png";
import bestPartImage from "../../images/best_part.png";
import "../../config";
import Offer from "components/grade/Offer";
import Calendly from "components/common/Calendly.js";

export default (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const Description1 = tw.span`inline-block mt-8`;
  const Description2 = tw.span`inline-block mt-8`;
  const Description3 = tw.span`inline-block mt-8`;
  const Description4 = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;
  console.log(props.match.params.course_code);
  return (
    <AnimationRevealPage>
      <MetaTags>
        <title>
          Ontario High School Credit Courses | Online Courses Ontario
        </title>
        <meta
          name="description"
          content="Learning Beam academy is an online high school in Ontario that offers flexible online Credit courses. Enroll in any of our OSSD credit courses to start today."
        />
        <meta
          name="keywords"
          content="Ontario High School Credit Courses, Online High School Credit Courses, Online Courses Ontario, OSSD Credit Courses Online, Ontario Secondary School Diploma"
        />
        <meta
          property="og:title"
          content="Ontario High School Credit Courses | Online Courses Ontario"
        />
        <meta
          property="og:image"
          content="/static/media/logo.358fb4cd.svg"
          alt="High school courses online Ontario"
        />
      </MetaTags>
      <Top
        //heading={<>Ontario's best online  <HighlightedText>schooling portal</HighlightedText></>}
        heading={<>Ontario High School Credit Courses</>}
        description="Learning Beam Academy issued individual credit scores help you enroll in top rated colleges and Universities. It helps students to opt for their favorite programs."
        description1="Regular Semester"
        description2="First week of September - 31 January"
        description3="First week of February - 30 June"
        description4="Summer"
        description5="July & August"
        imageSrc={courseMainImage}
        imageCss={imageCss}
        imageDecoratorBlob={true}
      />
      <hr style={{ width: "83%", marginLeft: "2.5em" }} />
      {/* <AllCourses
        data={props.match.params.course_code}
      /> */}

      <CoursesOffered heading={<>Ontario Credit Courses</>} />

      {/* <BestPart
        imageSrc={bestPartImage}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Register"
        watchVideoButtonText="Get a Free Demo"
      /> */}
      <HowDoesItWork heading={<>How Does It Work?</>} />
      <PeopleTestimonial />

      <Calendly />
      <Footer />
    </AnimationRevealPage>
  );
};
