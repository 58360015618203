import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Content from "components/misc/NotFoundContent.js";
import heroScreenshotImageSrc from "images/check-circle.png";
import macHeroScreenshotImageSrc from "images/check-circle.png";
import prototypeIllustrationImageSrc from "images/check-circle.png";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import Header from "components/common/HeaderNew.js";
import Footer from "components/common/Footer.js";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Header />
      <Content
        primaryButtonText="Back to Home"
        heading={
          <>
            404 Page Not Found
          </>
        }
      />
      <Footer />
    </AnimationRevealPage>
  );
}
