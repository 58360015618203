import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/common/Headings.js";
import { SectionDescription } from "components/common/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";
import { Container as ContainerBase, ContentWithPaddingXl as ContentBase } from "components/common/Layouts.js";
import { ReactComponent as CheckboxIcon } from "../../images/checkbox-circle.svg";
//import svg220 from "../../images/220.svg";
import { ReactComponent as svg220 } from "images/220.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";

const Container = tw(ContainerBase)`text-gray-100 -mx-8`;
const ContentWithPaddingXl = tw(
    ContentBase
)`relative z-10 mx-auto px-0 md:py-10 sm:py-20 flex flex-col max-w-screen-xl`;
const HeaderContainer = tw.div`mt-4 w-full flex flex-col items-start sm:px-6 md:px-8 lg:px-12 xl:px-20`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full font-serif text-3xl font-normal text-secondary-450 text-left`;
const Description = tw(SectionDescription)`w-full text-gray-300 text-center`;

const PlansContainer = tw.div`mt-12 flex flex-col items-center lg:flex-row lg:items-stretch lg:justify-between text-gray-900 font-medium sm:px-6 md:px-8 lg:px-12 xl:px-20`;

const CardMetaFeature = styled.div` 
  ${tw`flex items-center mt-4 mr-4 last:mr-0 font-semibold tracking-wide text-gray-600 uppercase text-xs`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-start sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

//sm:px-6 md:px-8 lg:px-12 xl:px-20

// const Plan = styled.div`
//   ${tw`w-full max-w-sm bg-white rounded-lg shadow-sm py-10 sm:px-10 lg:py-10 mx-3 flex flex-col justify-between mt-16 first:mt-0 lg:mt-0 shadow-raised`}
// `;

// const DecoratorBlob = styled(SvgDecoratorBlob3)`
//   ${tw`pointer-events-none absolute left-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
// `;

// const DecoratorBlob = tw(
//   SvgDecoratorBlob3
// )`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;

const DecoratorBlob = tw(
    svg220
)`-z-10 absolute top-0 left-0 w-64 h-48 transform -translate-x-8 translate-y-full`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


const Plan = styled.div`
  ${tw`w-full max-w-sm bg-white rounded-lg shadow-sm py-10 sm:px-10 lg:py-10 mx-3 flex flex-col justify-between mt-16 first:mt-0 lg:mt-0 shadow-raised`}
`;

const PlanHeader = styled.div`
  .nameAndFeaturedContainer {
    ${tw`flex flex-wrap flex-col sm:flex-row justify-between items-center`}
  }
  .name {
    ${tw`lg:text-lg xl:text-xl font-bold uppercase tracking-wider mr-3 text-secondary-450`}
  }
  .featuredText {
    ${tw`text-xs font-bold px-3 rounded py-2 uppercase bg-green-300 text-green-900 leading-none mt-4 sm:mt-0 w-full sm:w-auto text-center`}
  }

  .description {
    ${tw`mt-2 font-medium text-secondary-250 lg:text-sm xl:text-base`}
  }
`;
const PlanFeatures = styled.ul`
  ${tw`mt-4 flex-1 border-t lg:-mx-6 -mx-6 sm:-mx-10 py-10 px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10 text-gray-600 font-normal`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0 text-gray-600`}
    .icon {
      ${tw`w-6 h-6 text-primary-500 flex-shrink-0`}
    }
    .text {
      ${tw`font-normal text-sm text-gray-600 tracking-wide ml-3`}
    }
  }
`;
const PlanAction = tw.div`mt-4`;
const ActionButton = styled(PrimaryButtonBase)`
  ${tw`block text-center text-sm font-semibold tracking-wider w-full text-gray-100 bg-primary-500 px-2 py-4 rounded hover:bg-primary-700 focus:shadow-outline focus:outline-none transition-colors duration-300`}
`;

const WhiteBackgroundOverlay = tw.div`absolute inset-x-0 bottom-0 h-1/6 lg:h-1/3 bg-white z-0`;

const PrimaryButton = tw.button`font-bold sm:text-sm md:text-base sm:px-6 md:px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;

export default ({
    heading = "Current Openings",
    plans = null,
    primaryButtonText = "Apply"
}) => {
    const defaultPlans = [
        {
            name: "React Js Developer",
            location: "3-5 Years",
            experience: "Noida, UP, India",
            description: "Ready to take on a new role? We’re looking for change-makers. Opportunity creators. Status-quo shakers.We aspire to be an employer of choice for all because diversity powers our collective impact.",
            features: ["Some details about this course", "Some details about this course", "Some details about this course", "Some details about this course", "Some details about this course"],
        },
        {
            name: "Business analyst",
            location: "3-5 Years",
            experience: "Noida, UP, India",
            description: "Business analyst roles typically require a bachelors degree in any discipline, though employers may prefer a degree in a business-, computing-, economics-, or numeracy-related subject. You can get your degree through a full-time university study or through a business analyst degree apprenticeship.",
            features: ["Some details about this course", "Some details about this course", "Some details about this course", "Some details about this course", "Some details about this course"],
        },
        {
            name: "React Js Developer",
            location: "3-5 Years",
            experience: "Noida, UP, India",
            description: "Ready to take on a new role? We’re looking for change-makers. Opportunity creators. Status-quo shakers.We aspire to be an employer of choice for all because diversity powers our collective impact.",
            features: ["Some details about this course", "Some details about this course", "Some details about this course", "Some details about this course", "Some details about this course"],
        }
    ];
    if (!plans) plans = defaultPlans;

    return (
        <Container>
            <ContentWithPaddingXl>
                <HeaderContainer>
                    <Heading>{heading}</Heading>
                </HeaderContainer>

                <PlansContainer>
                    {/* <img class="round-full" src={svg220} style={{ position: 'absolute' }} /> */}
                    {plans.map((plan, index) => (
                        <Plan key={index} featured={plan.featured}>
                            <PlanHeader>
                                <span className="nameAndFeaturedContainer">
                                    <span className="name">{plan.name}</span>
                                    {plan.featured && <span className="featuredText">{plan.featured}</span>}
                                </span>

                                <CardMetaFeature>
                                    <BriefcaseIcon /> 3-5 Years
                                </CardMetaFeature>
                                <CardMetaFeature>
                                    <LocationIcon /> Noida, UP, India
                                </CardMetaFeature>
                            </PlanHeader>
                            <PlanFeatures>
                                <p className="description">{plan.description}</p>
                                {/* {plan.features.map((feature, index) => (
                                    <li className="feature" key={index}>
                                        <CheckboxIcon className="icon" />
                                        <span className="text">{feature}</span>
                                    </li>
                                ))} */}
                            </PlanFeatures>
                            <PlanAction>
                                <PrimaryButton as="a" href="/register">
                                    {primaryButtonText}
                                </PrimaryButton>
                            </PlanAction>
                        </Plan>
                    ))}
                </PlansContainer>
                <DecoratorBlob />
            </ContentWithPaddingXl>

            <WhiteBackgroundOverlay />
        </Container>
    );
};
