import React, { Component } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import CoursePlan from "components/course/CoursePlan.js";

import axios from "axios";
import "../../config.js";

const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;

const Card = styled.div`
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;

const Container = tw.div`relative sm:mb-2 md:mb-4 sm:mt-16 md:mt-20 lg:mx-10 bg-primary-100 rounded-md`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center  `;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-5/12 my-4 mr-4 rounded-md md:mt-0 text-center shadow-md p-0 bg-white py-6 `,
  props.textOnLeft
    ? tw`mx-auto md:ml-10 lg:ml-16 xl:ml-40 lg:mr-20 md:order-first`
    : tw`mx-auto md:ml-10 lg:ml-16 xl:ml-40 lg:mr-20 md:order-last`,
]);

const TextContent = tw.div` text-center md:text-left`;
const Title = tw.h4`text-xl sm:text-2xl md:text-3xl font-bold`;
const SubTitle = tw.h4`leading-relaxed text-lg`;
const Paragraph = tw.p`my-4 text-sm lg:text-base`;

class Top extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <Container>
        {this.props.courseData && (
          <TwoColumn style={{ padding: "0", marginLeft: "0", width: "100%" }}>
            <ImageColumn>
              <Card
                style={{
                  height: "auto",
                  paddingLeft: "2rem",
                  paddingTop: "3rem",
                  paddingBottom: "3rem",
                }}
              >
                <Title style={{ textAlign: "left" }}>
                  {this.props.courseData.course_title}
                </Title>
                <SubTitle style={{ textAlign: "left" }}>
                  ({this.props.courseData.course_code})
                </SubTitle>
                <Paragraph style={{ textAlign: "left" }}>
                  <b>Eligibility</b> - {this.props.courseData.eligibility}
                </Paragraph>
                <Paragraph style={{ textAlign: "left" }}>
                  <b>Department</b> - {this.props.courseData.department}
                </Paragraph>
                {/* <Paragraph style={{ textAlign: "left" }}>
                Published Date - {this.props.courseData.published_date}
              </Paragraph> */}
                <Paragraph style={{ textAlign: "left" }}>
                <b>Updated</b>   - {this.props.courseData.updated}
                </Paragraph>
                <Paragraph style={{ textAlign: "left" }}>
                <b>Credit Value</b>    - {this.props.courseData.credit_value}
                </Paragraph>
                <Paragraph style={{ textAlign: "left" }}>
                <b>Prerequisite</b>    - {this.props.courseData.prerequisite}
                </Paragraph>
              </Card>
            </ImageColumn>
            <TextColumn>
              {this.props.courseData && (
                <>
                  <CoursePlan
                    courseData={this.props.courseData}
                    data={this.props.data}
                    heading={<>Courses we offer</>}
                  />
                </>
              )}
              <TextContent></TextContent>
            </TextColumn>
          </TwoColumn>
        )}
      </Container>
    );
  }
}
export default Top;
