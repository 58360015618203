import React, {Component} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";
import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg"
import serverSecureIllustrationImageSrc from "images/building.jpg"
import Header from "components/common/Header.js";
import Payments from "components/course/Payments.js";

class FinanceProfile extends Component {
	constructor() {
		super();
		document.title = "Course - Learning Beam";
		this.state = {
			course_code: '',
			data : '',
			dummyEntry : '',
		};
	}

	render() {
		return (
			<AnimationRevealPage>
				<Header />
				<Payments />
				<Footer />
			</AnimationRevealPage>
		);
	}
}
export default (FinanceProfile);
