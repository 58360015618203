import React from "react";
import { useEffect } from 'react';
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import MetaTags from 'react-meta-tags';

import CoursesOffered from "components/tuition/Offer.js";
import HomeAboutUs from "components/common/MoreAboutUs.js";
import Features from "components/tuition/Features.js";
//import Stats from "components/common/Stats.js";
import WhyUs from "components/tuition/WhyUs.js";
//import BestPart from "components/common/BestPart.js";
//import Stats_New from "components/common/Stats_New.js";
//import Stats_New_Orig from "components/common/ThreeColCenteredStatsPrimaryBackground.js";
//import HomeCourses from "components/home/Courses.js";
import Hero from "components/tuition/Top.js";
import Footer from "components/common/Footer.js";

//import chefIconImageSrc from "images/chef-icon.svg";
//import celebrationIconImageSrc from "images/celebration-icon.svg";
//import shopIconImageSrc from "images/shop-icon.svg";

import bookOpen from "../../images/book-open.png";
import bitMap from "../../images/Bitmap.png";
import barChart from "../../images/bar-chart.png";
import checkCircle from "../../images/check-circle.png";

import homeImage from "../../images/home.png";
//import bestPartImage from "../../images/best_part.png";


export default () => {
  useEffect(() => {
        window.scrollTo(0, 0);
    });
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <MetaTags>
        <title>Learning Beam Academy| Best Tuition Centre| OCT Tutors </title>
        <meta name="description" content="Learning Beam Academy offers tuition for K to 12 Grades. Teachers are Ontario Certified Teachers(OCT). Best  tuition centre. Online classes facility available." />
        <meta name="keywords" content="OCT Tutors, Ontario Certified Teaching Staff, Tuition, K to 12, Grade 1 Ontario,  Grade 2 Ontario,  Grade 3 Ontario,  Grade 4 Ontario,  Grade 5 Ontario,  Grade 6 Ontario,  Grade 7 Ontario,  Grade 8 Ontario,  Grade 9 Ontario, Grade 10 Ontario, Online Tuition, Best Tuition Centre" />
        <meta property="og:title" content="Learning Beam Academy| Best Tuition Centre| OCT Tutors " />
        <meta property="og:image" content="/static/media/logo.358fb4cd.svg" alt="Online Tuition" />
      </MetaTags>
      <Hero
        //heading={<>Ontario's best online  <HighlightedText>schooling portal</HighlightedText></>}
        heading={<>Take your learning to next level</>}
        description="Please fill the form below to complete the registration process. Login instructions will be mailed to you once you are enrolled in your course."
        imageSrc={homeImage}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Register"
        primaryButtonUrl="/register_tuition"
        watchVideoButtonText=""
      />
      <hr style={{ width: '84%', marginLeft: '2.5em' }} />
      <CoursesOffered
        heading={
          <>
            Courses we offer
          </>
        }
      />
      {/* <HomeAboutUs /> */}

      <Features
        heading={
          <>
            Key features
          </>
        }
        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <WhyUs />
      {/* <Stats_New />
      <PeopleTestimonial />
      <BestPart
        imageSrc={bestPartImage}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Register"
        watchVideoButtonText="Get a Free Demo"
      /> */}

      <Footer />
    </AnimationRevealPage>
  );
}
