import React, { Component } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";

import Desc from "components/students/MatureStudentsDesc.js";
import NeedHelp from "../common/NeedHelp.js";
import MetaTags from "react-meta-tags";
import PeopleTestimonial from "components/common/Testimonials.js";
import Top from "components/students/Top.js";
import mainImage from "../../images/lang_course_main.png";
import aboutUsImage from "../../images/about.png";
import CoursesOffered from "components/course/Offer.js";
import HowDoesItWork from "components/common/HowDoesItWork.js";
import Calendly from "components/common/Calendly.js";
const imageCss = tw`rounded-4xl`;

class MatureStudents extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <AnimationRevealPage>
        <MetaTags>
          <title>Ontario Secondary School Diploma for mature students</title>
          <meta
            name="description"
            content="Learning Beam Academy is one of the finest and highly recognized schools by upgrading your skills or earning your Mature Student High School diploma."
          />
          <meta
            name="keywords"
            content="Ontario Secondary School Diploma, Mature Students, Ontario Secondary School"
          />
          <meta
            property="og:title"
            content="Ontario Secondary School Diploma for mature students"
          />
          <meta
            property="og:image"
            content="/static/media/logo.358fb4cd.svg"
            alt="Ontario Secondary School Diploma"
          />
        </MetaTags>
        <Top
          heading={<>Ontario Secondary School Diploma</>}
          description="Learning Beam Academy issued diploma keep your profile ahead of other students in the selection process of these universities and colleges."
          imageSrc={mainImage}
          imageCss={imageCss}
          altContent={"OSSD Diploma in Canada"}
          imageDecoratorBlob={true}
        />
        <hr style={{ width: "84%", marginLeft: "2.5em" }} />

        {/* <Desc heading={<>Who is a Mature Student?</>} /> */}
        <Desc heading={<>Get Ontario Secondary School Diploma With Us</>} />
        <CoursesOffered heading={<>Ontario High School Courses</>} />
        <HowDoesItWork heading={<>How Does It Work?</>} />
        <PeopleTestimonial />
        <Calendly />
        {/* <NeedHelp /> */}
        <Footer />
      </AnimationRevealPage>
    );
  }
}
export default MatureStudents;
