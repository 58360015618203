import React, { Component } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";
import serverSecureIllustrationImageSrc from "images/building.jpg"
import Header from "components/common/Header.js";
import Offer from "components/grade/Offer.js";
import BestPart from "components/common/BestPart.js";
import "../../config.js";
import bestPartImage from "../../images/best_part.png";
const qs = require('querystring');

const imageCss = tw`rounded-4xl`;
class AllCourses extends Component {
	constructor() {
		super();
		document.title = "Course - Learning Beam";
		this.state = {
			showPayment: false,
			fee: '',
		}
	}
	render() {
		return (
			<AnimationRevealPage>
				<Header />
				<Offer grade={this.props.match.params.grade} />
				<BestPart imageSrc={bestPartImage} imageCss={imageCss} imageDecoratorBlob={true} primaryButtonText="Register" watchVideoButtonText="Get a Free Demo" />
				<Footer />
			</AnimationRevealPage>
		);
	}
}
export default (AllCourses);
