import React, { Component } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";
import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg"
import serverSecureIllustrationImageSrc from "images/building.jpg"
import Header from "components/common/HeaderNew.js";
import CourseTop from "components/tuition_details/Top.js";
import CoursesDetails from "components/tuition_details/Details.js";
import Payment from "components/tuition_details/Payments.js";
import "../../config.js";
import axios from "axios";
const qs = require('querystring');

class Courses extends Component {
	constructor() {
		super();
		document.title = "Course - Learning Beam";
		this.state = {
			showPayment: false,
			fee: '',
			getCourseSubjectsData: [],
			getCourseSubjects: [],
			data: [],
		}
	}

	componentDidMount() {
		console.log("In componentDidMount - Courses")
		window.scrollTo(0, 0);
		console.log(this.props.match.params.code);
		//console.log(global.config.main.tuition_api_url);
		this.getCourseData();
	}

	getCourseData = () => {
		axios.get(global.config.main.tuition_api_url + '?' + this.props.match.params.code)
			.then(response => {
				console.log(response.data);
				this.setState({
					data: response.data,
					getCourseSubjects: response.data.getCourseSubjects,
					getCourseSubjectsData: response.data.getCourseSubjectsData,
					course_description: "",//response.data.getCourseDesc.course_desc,
				});
				this.state.data = response.data;
				this.state.dummyValue = "";
				console.log("getCourseData response")
				console.log(this.state.data);
			});
	}

	onUpdate(data) { console.log(data); this.setState({ showPayment: true, fee: data, }) }

	render() {
		return (
			<AnimationRevealPage>
				<Header />
				{this.state.showPayment != true ?
					<>
						{/*<CourseTop data={this.props.match.params.course_code} imageSrc={serverSecureIllustrationImageSrc} onUpdate={this.onUpdate.bind(this)}/> */}
						{this.state.data.length != 0 ?
							<CourseTop data={this.state.data} imageSrc={serverSecureIllustrationImageSrc} />

							:
							""
						}
						{this.state.data.length != 0 ?
							<CoursesDetails data={this.state.data} />
							:
							""
						}
					</>
					: (<Payment data={this.state.fee} />)}
				<Footer />
			</AnimationRevealPage>
		);
	}
}
export default (Courses);
