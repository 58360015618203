module.exports = global.config = {
  main: {
    //api_url: '<domain-name>/<path confifured in base_url in codeigniter config file>/appApi/course'

    // base_url: "http://3.138.198.156/LearningBeam/lb_react/src/api/appApi",
    
    base_url: "/api/appApi",
    tuition_api_url: "/api/appApi/tuition",

    course_pdf_url: "/upload/pdf/",

    ossd_requirment_pdf: "/upload/pdf/OSSD_DiplomaRequirements.pdf",
    ministry_letter_pdf: "/upload/pdf/ministry-approved-letter.pdf",
    /*********************** Test ********************/
    /*JOT_FORM_FREE_COURSE: 'https://form.jotform.com/211851064076452',
        JOT_REGISTRATION_FORM: 'https://form.jotform.com/212021163185443',
        JOT_CONTACT_US: 'https://form.jotform.com/211851227384455',
        JOT_TUITION_REGISTRATION_FORM: 'https://form.jotform.com/212021163185443'*/

    /*********************** Live ********************/
    JOT_FORM_FREE_COURSE: "https://form.jotform.com/210611583766255",
    JOT_REGISTRATION_FORM: "https://form.jotform.com/210962594650460",
    JOT_CONTACT_US: "https://form.jotform.com/210806141729250",
    JOT_TUITION_REGISTRATION_FORM: "https://form.jotform.com/212231519008445",

    //JOT_TUITION_REGISTRATION_FORM: 'https://form.jotform.com/211780950924459'
    //JOT_REGISTRATION_FORM: 'https://form.jotform.com/211850923130446',
  },
};
