import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
//import { Container, ContentWithPaddingXl } from "components/common/Layouts.js";
//import { ContentWithPaddingXl } from "components/common/Layouts.js";
import { SectionHeading } from "components/common/Headings.js";
//import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as svg220 } from "images/220.svg";
import { ReactComponent as CheckboxIcon } from "../../images/checkbox-circle.svg";

//import svg220 from "../../images/220.svg";

export const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto pt-8 lg:pt-20`;

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-4 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

const Container = tw.div`relative md:px-10`;
//const Container = tw.div`relative`

const DecoratorBlob2 = styled(svg220)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-80 w-80 transform translate-x-2/3 text-primary-500`}
`;
const Title = tw.h2`text-3xl font-bold font-black text-center`;

const HeadingContainer = tw.div`mb-12`;

const SubParagraph = tw.p`mt-12 mb-8 text-base text-secondary-200 mx-auto lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-center mt-2`;

const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 cursor-pointer focus:shadow-outline focus:outline-none transition duration-300`;

const FeatureText = tw.div`md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`mt-4 text-sm lg:text-base font-semibold text-secondary-200 text-left`;
const FeatureDescription = tw.p`mt-2 mb-8 text-sm lg:text-base text-gray-600 mx-auto lg:mx-0`;
const FeatureSubDescription = tw.div`mt-1 text-sm text-secondary-200 text-left`;

const PlanFeatures = styled.ul`
  ${tw`flex-1 lg:-mx-6 -mx-6 sm:-mx-10 pb-10 pt-5 px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10 xl:pt-5`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0 `}
    .icon {
      ${tw`w-6 h-6 text-primary-500 flex-shrink-0`}
    }
    .text {
      ${tw`font-semibold  text-secondary-250 tracking-wide ml-3`}
    }
  }
  .desc-text {
    ${tw`font-normal text-sm text-secondary-200 tracking-wide ml-8 pl-1`}
  }
`;

const openPdf = () =>
  window.open(
    "https://lbnew.thinkstraight.org/upload/pdf/OSSD_DiplomaRequirements.pdf",
    "blank"
  );

export default ({ heading = "" }) => {
  return (
    <Container>
      <DecoratorBlob2 />
      <ContentWithPaddingXl className={"pt-4 pb-2"}>
        <HeadingContainer>
          {/* {subheading && <Subheading>{subheading}</Subheading>} */}
          <Title>{heading}</Title>
        </HeadingContainer>
        {/* <FeatureHeading>You qualify as a mature student in Ontario if you are:</FeatureHeading>

        <PlanFeatures>
          <li className="feature">
            <CheckboxIcon className="icon" />
            <span className="text">{"at least 18 years old on or before December 31 of the school year;"}</span>
          </li>
          <li className="feature">
            <CheckboxIcon className="icon" />
            <span className="text">{"previously enrolled in a secondary school credit program for the purpose of obtaining an OSSD (or a credit);"}</span>
          </li>
          <li className="feature">
            <CheckboxIcon className="icon" />
            <span className="text">{"have been out of school for a period of at least one year before returning as an adult."}</span>
          </li>
        </PlanFeatures> */}
        <FeatureDescription>
          The Ontario Secondary School Diploma (OSSD) is the province's official
          secondary school diploma. To procure a secondary school diploma, you
          really want to acquire 30 secondary school credits. This program
          assists you with procuring secondary school credits toward your
          Ontario Secondary School Diploma (OSSD) and essential credits to enter
          postsecondary education or apprenticeship coaching.
        </FeatureDescription>
        {/* <FeatureHeading>1. Upgrade Your Marks For University or College — With Confidence</FeatureHeading>
        <FeatureDescription>Many Ontario high school students register with LBA as they search for online high school courses with flexibility. We offer a range of compulsory and elective courses for grades 9 through 12 in subject like english, science and math. We also offer upgrade courses which are courses you need to repeat in hopes or earning a higher mark for post-secondary admissions. These courses generally have a reduced workload. Connect with us to learn more.</FeatureDescription>

        <FeatureHeading>2. Finish Your Diploma — Anytime</FeatureHeading>
        <FeatureDescription>Some students enrol with LBA to earn a high school credit course in which they were previously unsuccessful or are missing. With our supportive Ontario Certified Teachers (OCT) and 24/7 online tutoring service we will do our best to help you succeed and get the credit(s) you need.</FeatureDescription>

        <FeatureHeading>3. Fast-Track — Conveniently</FeatureHeading>
        <FeatureDescription>Students also take courses with LBA to free up their timetable for the upcoming school year, to avoid timetable conflicts, or to earn a credit not available anywhere else. Learn more here. If you’re ready to enroll into our Ontario accredited high school courses please begin by visiting our How Does it Work? page. Included in the fee are all materials, instruction by an Ontario Certified Teacher, tests, modules, simulations examinations, and access to an online tutor which is a 24/7 on-demand and completely online service.</FeatureDescription> */}
        <PlanFeatures>
          <div>
            <li className="feature">
              <CheckboxIcon className="icon" />
              <span className="text">Total 30 Credits</span>
            </li>
            <p className="desc-text">
              18 Compulsory + 12 Optional (Including Credits from Grade 9, 10,
              11, 12)
            </p>
          </div>

          <div style={{ marginTop: "16px" }}>
            <li className="feature">
              <CheckboxIcon className="icon" />
              <span className="text">
                40 Hrs. of Involvement in Community Activities
              </span>
            </li>
            <p className="desc-text">
              Students need to spend total 40 Hrs. of community activities.
            </p>
          </div>

          <div style={{ marginTop: "16px" }}>
            <li className="feature">
              <CheckboxIcon className="icon" />
              <span className="text">The Provincial Literacy Requirement</span>
            </li>
            <p className="desc-text">
              Ontario Secondary School Literacy Test (OSSLT) <br />
              Standardized test for secondary students in Ontario <br />
              Testing your reading and writing skills <br />
              Students who were unsuccessful in Grade 10 will write the OSSLT on
              March 31, 2020 <br />
              Should you be unsuccessful for a 2nd time, you will be required to
              complete the Literacy Course (OLC4O) in grade 12
            </p>
          </div>
        </PlanFeatures>

        <Actions>
          <PrimaryButton as="a" onClick={openPdf}>
            View Requirements
          </PrimaryButton>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Actions>
      </ContentWithPaddingXl>
    </Container>
  );
};
