import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithVerticalPadding } from "components/common/Layouts.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg"


import { SectionHeading } from "components/common/Headings.js";
const Heading = tw(SectionHeading)`text-left text-primary-900 leading-snug xl:text-6xl`;




const Row = tw.div`flex flex-wrap overflow-hidden`;
const Column = tw.div``;
const TextColumn = tw(Column)`w-full overflow-hidden`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;


export default ({
  required = ["Communicating: Through the use of discussions, this course offers students the opportunity to share their understanding both in oral as well as written form. This also gives them the opportunity to broaden their understanding as they read and comment on the posts of others.","Independent Learning: This course provides students with opportunities to research aspects of business independently, with direction and guiding questions provided within the course.","Reflecting and Connecting: Assignments and discussions throughout the course will prompt students to reflect on concepts presented and how they connect to businesses and/or products that they encounter in their everyday lives","Self-Assessment: Through the use of interactive activities (e.g. multiple choice quizzes) students receive instantaneous feedback and are able to self-assess their understanding of concepts."],
}) => {
  return (
    <>
      <Container>
        <ContentWithVerticalPadding>
          <Row style={{"paddingTop":"0"}} className="courseDetailTableMargin">
		  <TextColumn>
				<p className="coursesSubheading" style={{marginTop:'1.5em'}}>Teaching and Learning Strategies:</p>
				<p className="strategySubHeading">The purpose of this course is to provide students with the foundational knowledge to be successful in business studies. To ensure that the purpose is met, a wide variety of instructional strategies are used to provide learning opportunities that accommodate a variety of learning styles, interests, and ability levels. The following strategies are used throughout the course to teach and learn the concepts presented:</p>

              <FeatureList className="featureListMargin">
                {required.map((required, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{required}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};
