import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";
import Header from "components/common/Header.js";
import BasicDetails from "components/common/Register_Demo.js";


export default () => {
	return (
		<AnimationRevealPage>
			<Header />
			<BasicDetails />
			<Footer />
		</AnimationRevealPage>
	);
}
