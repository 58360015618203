import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/common/Headings.js";
import CoursesDetailsFaq from "components/common/SingleCol.js";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto`;

//const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const TwoColumn = tw.div`flex flex-wrap -mx-5 overflow-hidden`;
const LeftColumn = tw.div`my-5 px-5 w-full overflow-hidden md:w-1/2 lg:w-1/2 xl:w-1/2`;
const RightColumn = tw.div`my-5 px-5 w-full overflow-hidden md:w-1/2 lg:w-1/2 xl:w-1/2`;

const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl``;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion.custom(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);
const TitleNew = tw.h4`text-3xl font-bold text-gray-700 text-center`;
export default ({
  subheading = "",
  heading = "Frequently Asked Questions",
  imageSrc = "https://images.unsplash.com/photo-1579427421635-a0015b804b2e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  imageContain = false,
  imageShadow = true,
  faqs = null
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "How does online schooling work?",
      answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
    },
    {
      question: "Can I take a course at Learning Beam Academy?",
      answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
    },
    {
      question: "How long does a course take?",
      answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
    },
    {
      question: "What is an upgrade course and how does it work?",
      answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
    },
    {
      question: "What are the tution fees?",
      answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
    }
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };
  const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
  const Form = tw.form`mx-auto max-w-xs`;
  const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

  const JOT_FORM_URL = "https://form.jotform.com/210341258280043";

  return (
    <Container>
      <Content style={{ paddingBottom: '0' }}>
        <TwoColumn>
          <LeftColumn>
            <FAQContent className="aboutUsFaqMargin">
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <TitleNew> {heading}</TitleNew>
              <FAQSContainer>
                <CoursesDetailsFaq
                  faqs={[
                    {
                      question: "How does online schooling work?",
                      answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
                    },
                    {
                      question: "Can I take a course at Learning Beam Academy?",
                      answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
                    },
                    {
                      question: "How long does a course take?",
                      answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
                    },
                    {
                      question: "How long does a course take?",
                      answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
                    },
                    {
                      question: "What is an upgrade course and how does it work?",
                      answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
                    },
                    {
                      question: "What are the tution fees?",
                      answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
                    }
                  ]} />
                {/*{faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText className="faqHeading">{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      {faq.answer}
                    </Answer>
                  </FAQ>
                ))}*/}
              </FAQSContainer>
            </FAQContent>
          </LeftColumn>
          <RightColumn>
            <center>
              <div style={{ backgroundColor: '#e5f1ff', height: '100%', borderRadius: '18px', padding: '2em', paddingBottom: '2.5em' }}>
                <p style={{ "color": "#131415", "fontFamily": "LibreBaskerville-Bold", "fontSize": "18px", "letterSpacing": "0", "lineHeight": "27px", "textAlign": "center" }}>
                  Still have questions?
		  		</p>
                <p style={{ "color": "#131415", "fontFamily": "LibreBaskerville-Bold", "fontSize": "18px", "letterSpacing": "0", "lineHeight": "27px", "textAlign": "center", marginBottom: '2em' }}>
                  Feel free to ask us
		  		</p>
                {/*<iframe title="your title" src={JOT_FORM_URL}></iframe>*/}
                <Form style={{ maxWidth: '100%' }}>
                  <Input type="text" placeholder="Full Name" />
                  <Input type="email" placeholder="Emai Id" />
                  <textarea placeholder="Your question/message" className="textAreaStyle">
                  </textarea>
                  <PrimaryButton as="a" >Submit</PrimaryButton>
                </Form>
              </div>
            </center>
          </RightColumn>
        </TwoColumn>
      </Content>
    </Container>
  );
};
