import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import * as ReactDOMServer from "react-dom/server";
//import { Helmet } from "react-helmet";
import CoursesOffered from "components/course/Offer.js";
import HomeAboutUs from "components/common/MoreAboutUs.js";
import Unique from "components/common/Unique.js";
import Stats from "components/common/Stats.js";
import PeopleTestimonial from "components/common/Testimonials.js";
import Calendly from "components/common/Calendly.js";
import BestPart from "components/common/BestPartHome.js";
import Stats_New from "components/common/Stats_New.js";
import Stats_New_Orig from "components/common/ThreeColCenteredStatsPrimaryBackground.js";
import HomeCourses from "components/home/Courses.js";
import Hero from "components/home/Top.js";
import School from "components/home/school.js";
import Footer from "components/common/Footer.js";
import MetaTags from "react-meta-tags";
import NeedHelp from "../common/NeedHelp.js";
//import chefIconImageSrc from "images/chef-icon.svg";
//import celebrationIconImageSrc from "images/celebration-icon.svg";
//import shopIconImageSrc from "images/shop-icon.svg";

import bookOpen from "../../images/book-open.png";
import bitMap from "../../images/Bitmap.png";
import barChart from "../../images/bar-chart.png";
import checkCircle from "../../images/check-circle.png";

//import homeImage from "../../images/home_image.png";
import homeImage from "../../images/home_3.png";
import bestPartImage from "../../images/best_part.png";

export default () => {
  // useEffect(() => {
  //   document.title = 'Best Online Schooling in Ontario - Get a free Demo';
  //   document.description = 'Best Online Schooling in Ontario, Online Schooling in Ontario, Online Schooling, Ontario Best Online virtual high school, Best Ontario Online School, Credit Courses, Grade 9, Grade 10, Grade 11, Grade 12, Free online classes';
  // }, []);
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <MetaTags>
        <title>
          Best Online High School Ontario | Online Private High School Ontario
        </title>

        {/*         
<script async src="https://www.googletagmanager.com/gtag/js?id=G-P852WFPTQG"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-P852WFPTQG');
</script>
<script async src="https://www.googletagmanager.com/gtag/js?id=G-P852WFPTQG"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-P852WFPTQG');
</script>
<meta name="google-site-verification" content="5qFLbzSyBQalZW1Fpkcq_p0aMSbXelVZ8Drlzbf4A5A" /> */}

        <meta
          name="description"
          content=" Learning Beam Academy is the best online Private high school in Ontario that offers online high school courses. Looking for Online schooling in Toronto? Check our OSSD Courses now!"
        />
        <meta
          name="keywords"
          content="Best Online High School Ontario, High School Ontario, Online Schooling in Toronto, Online High School Courses, Online Private High School Ontario
"
        />
        <meta
          property="og:title"
          content="Best Online High School Ontario | Online Private High School Ontario"
        />
        <meta
          property="og:image"
          content="/static/media/logo.358fb4cd.svg"
          alt="best online schooling in Ontario"
        />
      </MetaTags>

      
      <Hero
        //heading={<>Ontario's best online  <HighlightedText>schooling portal</HighlightedText></>}
        heading={<>Ontario's best Online Private High School</>}
        description="We comply with all the regulations and
        guidelines laid down by the Ontario Ministry of Education, Canada."
        desc={
          <p>
            <a
              href={global.config.main.ministry_letter_pdf }
              target="_blank" rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              BSID#889197
            </a>
            , Ministry Approved
          </p>
        }
        //imageSrc="https://cdn.zeplin.io/5fcf103afc7ab146a23a9331/assets/784E5DF6-12CC-42B8-B917-E524224A1334.png"
        imageSrc={homeImage}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Register"
        primaryButtonUrl="/register"
        watchVideoButtonText="Get a Free Demo"
      />
      <hr style={{ width: "84%", marginLeft: "2.5em" }} />
      <CoursesOffered heading={<>Ontario High School Credit Courses</>} />
      <School />
      <HomeAboutUs />

      <Unique
        heading={<>What makes us unique?</>}
        cards={[
          {
            imageSrc: bookOpen,
            title: "100% Satisfaction",
            url: "https://www.google.com/search?q=learning+beam+academy&sxsrf=APq-WBvTnXyFnp3T-liggr2y_VqeTelHHA%3A1645866055626&source=hp&ei=R-wZYtHUI6GmwAO8i6igCg&iflsig=AHkkrS4AAAAAYhn6VzJle9Gdheym8o5fu3ENS9uPZGP-&ved=0ahUKEwiRlN3sgJ32AhUhE3AKHbwFCqQQ4dUDCAc&uact=5&oq=learning+beam+academy&gs_lcp=Cgdnd3Mtd2l6EAMyBAgjECc6CwgAEIAEELEDEIMBOgUIABCABDoLCC4QgAQQsQMQgwE6CAgAEIAEELEDOhEILhCABBCxAxCDARDHARCvAToFCC4QgAQ6CwguEIAEEMcBEK8BOgYIABAWEB46CAgAEBYQChAeUABY6SVgoyloAHAAeAGAAb8DiAGbKpIBCjAuMy4xMC42LjGYAQCgAQE&sclient=gws-wiz",
          },
          {
            imageSrc: bitMap,
            title: "Free Demo",
            url: "/best-high-school-ontario",
          },
          {
            imageSrc: barChart,
            title: "100% Success Result",
          },
          {
            imageSrc: checkCircle,
            title: "Live Assessment",
          },
        ]}
        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <Stats_New />
      <PeopleTestimonial />
      <BestPart
        imageSrc={bestPartImage}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Join Us"
        watchVideoButtonText=""
      />
      {/* <NeedHelp /> */}

      {/* <Calendly /> */}

      {/* <PopupWidget
        url="https://calendly.com/learningbeamacademy"
        rootElement={document.getElementById("root")}
        text="Click here to schedule!"
        textColor="#ffffff"
        color="#00a2ff"
      /> */}
      {/* <PopupButton
        url="https://calendly.com/learningbeamacademy"
        
        rootElement={document.getElementById("root")}
        text="Click here to schedule!"
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
