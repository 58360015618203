import React, {useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/common/Headings.js";
import { SectionDescription } from "components/common/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/common/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block w-full max-w-xs sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;

const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-center md:items-start relative`;
const Plan = styled.div`
  ${tw`w-full max-w-72 mt-16 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  ${props =>
    props.featured &&
    css`
      ${tw`border-2 border-gray-200 shadow-none`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-8 -mx-8 bg-gray-100 rounded-t-lg`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm`}
  .feature {
    ${tw`mt-5 first:mt-0 font-semibold text-gray-500`}
  }
`;

const PlanAction = tw.div`px-4 pb-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

export default ({
  subheading = "Pricing",
  heading = "Flexible Plans.",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  plans = null,
  primaryButtonText = "Buy Now",
  planDurations = [
    {
      text: "",
      switcherText: "Grade 9",
    },
    {
      text: "",
      switcherText: "Grade 10",
    },
	{
      text: "",
      switcherText: "Grade 11",
    }
  ]
}) => {
	const defaultPlans = [
	{
		name : ["Intergrated Arts","Dummy 1","Dummy 2","Dummy 3"],
		durationPrices: ["1", "2","3"],
	},
	{
		name: ["Introduction to Business","Dummy 4","Dummy 5","Dummy 6"],
		durationPrices: ["10", "11","12"],
		featured: true
	},
	{
		name : ["Information & communication technology in business","Dummy 7","Dummy 8","Dummy 9"],
		durationPrices: ["100", "200","300"],
		featured: true
	},
	{
		name : ["Issues in Canadian Geography","Dummy 10","Dummy 11","Dummy 12"],
		durationPrices: ["100", "200","300"],
		featured: true
	},
	{
		name : ["English Academic","Dummy 13","Dummy 14","Dummy 15"],
		durationPrices: ["100", "200","300"],
		featured: true
	},
	{
		name : ["English Applied","Dummy 16","Dummy 17","Dummy 18"],
		durationPrices: ["100", "200","300"],
		featured: true
	},
	{
		name : ["Core French","Dummy 19","Dummy 20","Dummy 21"],
		durationPrices: ["100", "200","300"],
		featured: true
	},
	{
		name : ["Core French","Dummy 22","Dummy 23","Dummy 24"],
		durationPrices: ["100", "200","300"],
		featured: true
	},
	{
		name : ["Principle of Mathematics","Dummy 25","Dummy 26","Dummy 27"],
		durationPrices: ["100", "200","300"],
		featured: true
	}
	];

  if (!plans) plans = defaultPlans;

  const [activeDurationIndex, setActiveDurationIndex] = useState(0);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        <PlanDurationSwitcher>
          {planDurations.map((planDuration, index) => (
            <SwitchButton active={activeDurationIndex === index} key={index} onClick={() => setActiveDurationIndex(index)}>{planDuration.switcherText}</SwitchButton>
          ))}
        </PlanDurationSwitcher>
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured} style={{"height":"150px","width":"255px","borderRadius":"10px","backgroundColor":"#FFFFFF","boxShadow":"0 17px 54px 0 rgba(182,182,182,0.3)"}}>
              <PlanHeader>
                <span className="priceAndDuration">
                  <span className="price" style={{"height":"50px","width":"102px","color":"#34465B","fontFamily":"LibreBaskerville-Regular","fontSize":"18px","fontWeight":"600","letterSpacing":"-0.27px","lineHeight":"27px","textAlign":"center"}}>{plan.durationPrices[activeDurationIndex]}</span>
                </span>
                  <span className="price" style={{"height":"21px","width":"72px","color":"#607B98","fontFamily":"LibreBaskerville-Regular","fontSize":"15px","fontWeight":"500","letterSpacing":"-0.27px","lineHeight":"23px","textAlign":"center"}}>{plan.name[activeDurationIndex]}</span>
              </PlanHeader>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
