import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";
import Header from "components/common/HeaderNew.js";
import ContactBasic from "components/contact/ContactBasic";
import ContactNew from "components/contact/ContactNew";
import Top from "components/contact/Top.js";
import courseMainImage from "../../images/courseMain.png";

import "../../config";

const imageCss = tw`rounded-4xl`;

export default () => {
  return (
    <AnimationRevealPage>
      {/* <Header /> */}
      <Top
        heading={<>Best High School In Ontario</>}
        description="Earn Your OSSD Diploma and Credit Courses online with a highly flexible schedule. Join Us or Get Started Online"
        imageSrc={courseMainImage}
        imageCss={imageCss}
        imageDecoratorBlob={true}
      />
      <hr style={{ width: "84%", marginLeft: "2.5em" }} />
      <ContactNew />
      <Footer />
    </AnimationRevealPage>
  );
};
