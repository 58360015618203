import React, { Component } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithVerticalPadding } from "components/common/Layouts.js";
import Sphere from "../../images/sphere.png";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/dot-pattern.svg"


import { SectionHeading } from "components/common/Headings.js";
import axios from "axios";
import "../../config.js";
const qs = require('querystring');
const Heading = tw(SectionHeading)`text-left text-primary-900 leading-snug xl:text-6xl`;

const Title = tw.h4`sm:text-xl md:text-2xl text-3xl font-bold text-gray-700 text-left pt-12 pb-8`;
const SubTitle = tw.h4`leading-relaxed font-bold text-sm lg:text-base`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;


const Row = tw.div`flex flex-wrap overflow-hidden`;
const Column = tw.div``;
const TextColumn = tw(Column)`w-full overflow-hidden`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const SubTitle1 = tw.h4`leading-relaxed font-bold text-lg mx-12 pt-8`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 mx-12`;
const Paragraph1 = tw.p`my-2 lg:my-2 text-sm lg:text-base text-gray-600 mx-4`;


const required = ["Spreadsheet software (e.g. Microsoft ExcelTM, Mac NumbersTM, or equivalent)", "Word processing software (e.g. Microsoft WordTM, Mac PagesTM, or equivalent)"];
const provided = ["This course is entirely online and does not require or rely on any textbook."];

class Requirement extends Component {
  constructor() {
    super();
    this.state = {
      resourcesProvided: [],
      resourcesRequired: [],
    }
  }
  componentDidMount() {
    this.setState({
      resourcesProvided: this.props.courseData.getResourcesProvided,
      resourcesRequired: this.props.courseData.getResourcesRequired,
    });
    this.state.resourcesProvided = this.props.courseData.getResourcesProvided;
  }
  getCourseData = () => {
    axios.get(global.config.main.api_url + '?' + this.props.data)
      .then(response => {
        this.setState({
          resourcesProvided: response.data.getResourcesProvided,
          resourcesRequired: response.data.getResourcesRequired,
        });
        this.state.resourcesProvided = response.data.getResourcesProvided;
      });
  }
  render() {

    return (
      <Container>
        <Row style={{ "paddingTop": "0" }} >
          <TextColumn>
            <Title>Course Requirements</Title>
            <SubTitle>Resources required by the student</SubTitle>
            <FeatureList style={{ marginTop: '20px' }}>
              {this.state.resourcesRequired.map((data, index) => (
                <Feature key={index}>
                  <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                  <Paragraph1>{data.resources_required_subheading}</Paragraph1>
                </Feature>
              ))}
            </FeatureList>
            {this.state.resourcesProvided != false ?
              <SubTitle>Resources provided by us</SubTitle>
              : ""}
            <FeatureList style={{ marginTop: '20px' }}>
              {this.state.resourcesProvided != false ?
                this.state.resourcesProvided.map((data, index) => (
                  <Feature key={index}>
                    <img src={Sphere} alt="logo" style={{ width: '9px' }} />
                    <Paragraph1>{data.resources_provided_heading}</Paragraph1>
                  </Feature>
                )) : ''}
            </FeatureList>
          </TextColumn>
        </Row>
      </Container>
    )
  }
};
export default (Requirement);
