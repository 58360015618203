import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle } from "../common/Headings.js";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import { ReactComponent as ArrowLeftIcon } from "../../images/arrow-left-2-icon.svg";
import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right-2-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-4.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-5.svg";

import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";

import "slick-carousel/slick/slick.css";

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-4 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

const Heading = tw(
  HeadingTitle
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const CardButton = tw(PrimaryButtonBase)`text-sm`;

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const TestimonialSliderContainer = tw.div`mt-24`;
const TestimonialSlider = styled(Slider)``;
const Testimonial = tw.div`flex! flex-col items-center md:items-stretch md:flex-row md:justify-center outline-none`;
const TextContainer1 = styled.div`
  ${tw`md:mx-3 lg:mx-6 w-2/3 md:w-4/12 rounded flex items-center max-w-xs md:max-w-none`}
  img {
    ${tw`rounded`}
  }
`;

const MainContainer = tw.div`flex flex-wrap -mx-5 overflow-hidden sm:-mx-4 md:-mx-4`;
const TextContainer = tw.div`my-5 px-5 w-full overflow-hidden sm:my-4 sm:px-4 md:my-4 md:px-4 md:w-1/2`;
const RightContainer = tw.div`my-5 px-5 w-full overflow-hidden sm:my-4 sm:px-4 md:my-4 md:px-4 md:w-1/2`;

const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0`;
const Quote = tw.blockquote`text-center md:text-left font-medium text-xl lg:text-2xl xl:text-3xl`;
const CustomerInfo = tw.div`px-5 lg:px-10 text-center md:text-left mt-4 md:mt-0`;
const CustomerName = tw.h5`font-bold text-lg lg:text-xl xl:text-2xl text-primary-500`;
const CustomerTitle = tw.p`font-medium text-sm`;

const QuotesLeft = tw(
  QuotesLeftIcon
)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute top-0 left-0`;
const QuotesRight = tw(
  QuotesRightIcon
)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute bottom-0 right-0`;

const SliderControlButtonContainer = styled.div`
  ${tw`absolute top-0 h-full flex items-end md:items-center z-20`}
  button {
    ${tw`text-secondary-500 hover:text-primary-500 focus:outline-none transition duration-300 transform hover:scale-125 transform -translate-y-2/3 md:translate-y-0`}
    svg {
      ${tw`w-8`}
    }
  }
`;

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="right-0">
    <button {...props}>
      <ArrowRightIcon />
    </button>
  </SliderControlButtonContainer>
);
const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="left-0">
    <button {...props}>
      <ArrowLeftIcon />
    </button>
  </SliderControlButtonContainer>
);

const PrimaryButton = tw.button`font-normal px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;

export default () => {
  /*
   * You can modify the testimonials shown by modifying the array below
   * You can add or remove objects from the array as you need.
   */
  const testimonials = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
      quote:
        "Education is what remains after one has forgotten what one has learned in school.",
      customerName: "—  Albert Einstein",
    },
  ];
  return (
    <Container
      style={{
        backgroundColor: "#E5F1FF",
        marginLeft: "-2rem",
        marginRight: "-2rem",
        padding: "1em",
      }}
    >
      <Content style={{ paddingBottom: "0" }}>
        <TestimonialSliderContainer style={{ marginTop: "0" }}>
          <TestimonialSlider
            nextArrow={<NextArrow />}
            prevArrow={<PreviousArrow />}
          >
            {testimonials.map((testimonial, index) => (
              <Testimonial key={index}>
                <MainContainer>
                  <TextContainer>
                    <QuoteContainer>
                      <QuotesLeft
                        style={{ color: "#b7d7ff", marginLeft: "2em" }}
                      />
                      <Quote>{testimonial.quote}</Quote>
                    </QuoteContainer>
                    <CustomerInfo>
                      <CustomerName>{testimonial.customerName}</CustomerName>
                      <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>
                    </CustomerInfo>
                  </TextContainer>
                  <RightContainer>
                    <Description>
                      Learning Beam Academy is a fully online, private,
                      asynchronous secondary school offering high-quality
                      Ontario Secondary School Diploma (OSSD) & Credit courses
                      to students all over the world.
                    </Description>
                    <Description>BSID#889197, Ministry Approved</Description>
                    <Description
                      style={{ marginBottom: "20px", marginTop: "40px" }}
                    >
                      {/*<PrimaryButton as="a" href="/about">More About Us</PrimaryButton>*/}
                      <Link to="/online-schooling-in-ontario">
                        <PrimaryLink>More About Us</PrimaryLink>
                      </Link>
                    </Description>
                    {/* <Link to="/about">
                      <CardButton style={{ "height": "60px", "width": "200px", "borderRadius": "10px", "backgroundColor": "rgb(0, 122, 255)", "marginTop": "4em", "boxShadow": "rgba(0, 122, 255, 0.35) 0px 10px 30px 0px", "borderStyle": "solid", "borderWidth": "0px", "borderImageSource": "linear-gradient(rgb(92, 170, 255), rgb(0, 122, 255))", "borderImageSlice": "1", "backgroundImage": "linear-gradient(rgb(0, 122, 255), rgb(0, 122, 255)), linear-gradient(rgb(92, 170, 255), rgb(0, 122, 255))", "backgroundOrigin": "border-box;", "color": "white", "font-weight": "800", marginLeft: "1em" }}>More About Us</CardButton>
                    </Link> */}
                  </RightContainer>
                </MainContainer>
              </Testimonial>
            ))}
          </TestimonialSlider>
        </TestimonialSliderContainer>
      </Content>
      {/*<DecoratorBlob1 />*/}
      <DecoratorBlob2 />
    </Container>
  );
};
