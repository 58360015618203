import React, { Component } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";
import Desc from "components/lang_course/Desc.js";
import BestPart from "components/common/BestPart.js";
//import AboutUs from "components/common/AboutUs.js";
import bestPartImage from "../../images/best_part.png";

import Features from "components/lang_course/Features.js";
import HowItWorks from "components/lang_course/HowItWorks.js";

import WhyUs from "components/lang_course/WhyUs.js";
import Destinations from "components/lang_course/Destinations.js";
import OurCourses from "components/lang_course/OurCourses.js";
import Top from "components/lang_course/MainTop.js";
import mainImage from "../../images/lang_course_main.png";

const imageCss = tw`rounded-4xl`;

class Lang extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}
	render() {
		return (
			<AnimationRevealPage>
				<Top
					heading={<>Take your learning to next level</>}
					description="Please fill the form below to complete the registration process. Login instructions will be mailed to you once you are enrolled in your course."
					imageSrc={mainImage}
					imageCss={imageCss}
					imageDecoratorBlob={true}
				/>
				<hr style={{ width: '83%', marginLeft: '2.5em' }} />
				<OurCourses />
				<WhyUs />

				<Features
					heading={
						<>
							Key features
          				</>
					}
					imageContainerCss={tw`p-2!`}
					imageCss={tw`w-20! h-20!`}
				/>

				{/* <Destinations /> */}
				<Footer />
			</AnimationRevealPage>
		)
	}
}
export default (Lang);
